
import React, { useState,useEffect } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import ChurchFormPageOneAdd from './ChurchFormPageOneAdd';
import ChurchFormPageTwoAdd from './ChurchFormPageTwoAdd';
import FinancialFormPageOneAdd from './FinancialFormPageOneAdd';
import ChurchFormPageThreeAdd from './ChurchFormPageThreeAdd';

export const ChurchFormAddTabs = () => {

  const history = useHistory();
  const formData = new FormData();
  const [inputs, setInputs] = useState({});
  const [imageData, setImageData] = useState({});
  const [err, setError] = useState(null);
  const [valuesAfterPercent, setvaluesAfterPercent] = useState([]);
  console.log("valuesAfterPercent34",valuesAfterPercent)
  const [fillActive, setFillActive] = useState('tab1'); 
  const churchFormApiCall = async (e) => {


try{




    // if(valuesAfterPercent.some((element) => element > 17 || element < 1)){
    //   toast("Ranking from 'Fill Ranking' section should not be greater than 17 or less than 1")
    //   return;
    // }
    let hasDuplicates = false;
const seenValues = new Set();

for (const value of valuesAfterPercent) {
  if (seenValues.has(value)) {
    hasDuplicates = true;
    break;
  }
  seenValues.add(value);
}
if(hasDuplicates){
  toast("Ranking from 'Fill Ranking' section should have distinct values")
  return;
}


    try {
      console.log("inputs84",(inputs));
      let filez;
      if(imageData.Snapshot_current_Membership){
filez=imageData.Snapshot_current_Membership;
delete imageData.Snapshot_current_Membership;

      }

      const res = await axios.post(`${process.env.REACT_APP_URL}/api/churchFormData/`, inputs, { withCredentials: true });  
      console.log("Snapshot_current_Membership",(res.data));
      if(imageData.isImage==true){
      
  
        formData.append('image', filez.file);
        formData.append('id', (res.data));
        var response = await axios.post(`${process.env.REACT_APP_URL}/api/uploadChurchImage/`, formData, { withCredentials: true });  

      }
      toast('Form Submitted Successfully');
      localStorage.removeItem("submissions");
      setTimeout(function () {
        // window.location.replace('/admin/ListSubmissions');
        history.push('/admin/ListSubmissions');
      }, 2000);

    } catch (err) {
      console.log("churchFormData",err);
      toast(err);
    }

  }
  catch (err){
  console.log("err2333",err)
  }
  }
  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };

  return (
    <><ToastContainer />
      <MDBTabs fill className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab1')} active={fillActive === 'tab1'}>
            Fill General Data
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab2')} active={fillActive === 'tab2'}>
            Fill Questions
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab4')} active={fillActive === 'tab4'}>
            Fill Ranking
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab3')} active={fillActive === 'tab3'}>
            Fill Financial Data
          </MDBTabsLink>
        </MDBTabsItem>

      </MDBTabs>

<form  onSubmit={(e) => { e.preventDefault(); churchFormApiCall(); console.log("gsd");}} >
      <MDBTabsContent>
        <MDBTabsPane show={fillActive === 'tab1'}><ChurchFormPageOneAdd inputs={inputs} setInputs={setInputs} churchFormApiCall={churchFormApiCall} formData={formData} setImageData={setImageData}/></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab2'}><ChurchFormPageTwoAdd inputs={inputs} setInputs={setInputs} churchFormApiCall={churchFormApiCall} /></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab4'}><ChurchFormPageThreeAdd inputs={inputs} setInputs={setInputs}  churchFormApiCall={churchFormApiCall} valuesAfterPercent={valuesAfterPercent} setvaluesAfterPercent={setvaluesAfterPercent}/></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab3'}><FinancialFormPageOneAdd inputs={inputs} setInputs={setInputs} churchFormApiCall={churchFormApiCall} /></MDBTabsPane>
      
      </MDBTabsContent>
      <div style={{textAlign:"center"}} >

      <button type="submit" className="btn btn-success" >
           Submit
         </button> 
         <p>Please make sure to fill required fields from all tabs before submitting</p>
         
          </div>
          </form>
  
    </>
  );
}
