import React, {useRef, useState,useEffect,useMemo } from "react";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import YearPicker from "react-year-picker";
import ImageUploading from "react-images-uploading";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const PastorFormPageFour = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [awa, setAwa] = useState([]); 
  let awaObject={};
 
  const columns = useMemo(
    () => [
   
      {
        field: 'year',
        title: 'Year',
 
        //size: 40,
     
      },
      {
        field: 'percentage',
        title: 'Attendance Percentage',
     
        //size: 20,
    
      },   ],
      [],
    );

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

 

  const [err, setError] = useState(null);

  const handleChange = (e) => {
    // props.setInputs(prevState => ({ ...prevState.inputs,
    //     clergy: {
    //       ...prevState.inputs.clergy,
    //       [e.target.name]: e.target.value
    //     }
    
    // }));
    console.log(e)

// props.setInputs(props.inputs)
    props.setRankingObject((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    props.setEdit((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if(e.target.type=="checkbox"){
  
      if(e.target.checked==false){
      console.log("checkbox33false")
   props.inputs.clergy[e.target.name]="false";
  props.setInputs(props.inputs)
  props.setEdit((prev) => ({ ...prev, [e.target.name]: "false" }));
      }
      if(e.target.checked==true){
        console.log("checkbox33true")
     props.inputs.clergy[e.target.name]="true";
    props.setInputs(props.inputs)
    props.setEdit((prev) => ({ ...prev, [e.target.name]: "true" }));
        }
  
    }

  };
  const inputDate = (e) => {
    awaObject.year=e;
  };
  const inputAttendance = (e) => {
    awaObject.percentage=e.target.value;
      };
    
  const handleSubmit = async (e) => {
    console.log("handleSubmit55")
    e.preventDefault();
    window.location.replace('/churchpagetwo');
  };
  const defaultMaterialTheme = createTheme();
  return (
               <div id = "frm1"    >
                <br/>  <br/>  <br/>


   

        <div className="row" style={{margin:"1px"}}>  
    <div className="col">
        <table className="table" >
  <thead>
    <tr>
      {/* <th scope="col">#</th> */}
      <th scope="col" style={{width:"40%"}}>Skill</th>
      <th scope="col" >Rating</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      {/* <td  style={{
    width: "15%"
}}> 
<input name="input1" required type="number" min="1" max="10"className="form-control"style={{width:"100%"}}  placeholder={inputs.input1||""}  onChange={handleChange}/></td> */}

     
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Biblical: Faithful to the biblical text 

</td> 
 {<td  style={{
    width: "15%"
}}> 
<input name="Clergy50" required type="number" min="1" max="10"className="form-control"style={{width:"100%"}}  placeholder={props.inputs.clergy.Clergy50||""} onChange={handleChange}/></td> }

    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">2</th>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Wesleyan: Consistent with Wesleyan theology</td>
      <td  style={{
    width: "15%"
}}> 
<input name="Clergy51" required type="number" min="1" max="10"className="form-control"style={{width:"100%"}}   placeholder={props.inputs.clergy.Clergy51||""} onChange={handleChange}/></td>
     
      {/* <td style={{
fontStyle: "italic",fontSize:"small"}}>Planning and conducting worship services, Preparing computer information for services, and working with others who lead and participate in worship.
</td> */}
    </tr>
   
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Engaging: Gets and keeps people's attention</td>
      <th style={{display:"none"}} scope="row">3</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy52" required type="number" min="1" max="10"className="form-control"style={{width:"100%"}}   placeholder={props.inputs.clergy.Clergy52||""}  onChange={handleChange}/></td>
      
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Relevant: Be relevant to the daily life of the congregation</td>
      
      <th style={{display:"none"}} scope="row">4</th>
      <td  style={{
    width: "15%"
}}>
     <input name="Clergy53" required type="number" min="1" max="10"className="form-control"style={{width:"100%"}}  placeholder={props.inputs.clergy.Clergy53||""}  onChange={handleChange}/></td>
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Relational: Winsome, authentic and approachable pulpit presence</td>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy54" required type="number" min="1" max="10"className="form-control"style={{width:"100%"}}   placeholder={props.inputs.clergy.Clergy54||""} onChange={handleChange}/></td>
     
    
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Grounded: Speaks out of a deep and authentic spiritual life</td>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> 
<input name="Clergy55" required type="number" min="1" max="10"className="form-control"style={{width:"100%"}}   placeholder={props.inputs.clergy.Clergy55||""}  onChange={handleChange}/></td>
     
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Eloquent: Skillful use of humor, stories and other communication tools</td>
      <th style={{display:"none"}} scope="row">6</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy56" required type="number" min="1" max="10"className="form-control"style={{width:"100%"}}   placeholder={props.inputs.clergy.Clergy56||""}  onChange={handleChange}/></td>
     
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Deepening: Draws people into a deeper relationship with God. </td>
      <th style={{display:"none"}} scope="row">7</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy57" required type="number" min="1" max="10"className="form-control"style={{width:"100%"}}   placeholder={props.inputs.clergy.Clergy57||""}  onChange={handleChange}/></td>
     
     
    </tr>
 
   
  </tbody>
</table>

 
</div>
<div className="col">
        <table className="table" >
  <thead>
    <tr>
      {/* <th scope="col">#</th> */}
      <th scope="col" style={{width:"40%"}}>Skill</th>
      <th scope="col" >Rating</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      {/* <td  style={{
    width: "15%"
}}> 
<input name="input1" required type="number" min="1" max="10"className="form-control"style={{width:"100%"}}  placeholder={inputs.input1||""}  onChange={handleChange}/></td> */}

     
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Focused: Clear and focused on what the preacher wants people to know, feel and do
</td>
 {<td  style={{
    width: "15%"
}}> 
<input name="Clergy58" required type="number" min="1" max="10"className="form-control"style={{width:"100%"}}   placeholder={props.inputs.clergy.Clergy58||""}  onChange={handleChange}/></td> }

    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Evangelistic: Inspires, motivates and invites people to make a transformative relationship with Jesus Christ.</td>
      <th style={{display:"none"}} scope="row">2</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy59" required type="number" min="1" max="10"className="form-control"style={{width:"100%"}}   placeholder={props.inputs.clergy.Clergy59||""}  onChange={handleChange}/></td>
     
     
    </tr>
   
    
    
 
   
  </tbody>
</table>

 
</div>

  {/* <div className="form-group">
    <label for="exampleFormControlTextarea1">1.	What other critical pastoral gifts and skills are needed to help you move toward that vision? <span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question15"  rows="1" placeholder={inputs.question15||""}  onChange={handleChange}></textarea>
  </div> */}
</div>

<div className="form-group">
  <label>
  With what styles of worship do you feel comfortable/competent?
  <span class="required"></span> </label>
  <br />
  <div className="row" style={{margin:"1px"}}>
    <div className="col-12 col-sm-6">
      <input type="checkbox" id="option1"  checked={props.inputs.clergy.Clergy60 && props.inputs.clergy.Clergy60=="true"} name="Clergy60" value="contemporary" onChange={handleChange} />
      <label htmlFor="option1">contemporary <span class="required"></span> </label>
    </div>
    <div className="col-12 col-sm-6">
      <input type="checkbox" id="option2"  checked={props.inputs.clergy.Clergy61 && props.inputs.clergy.Clergy61=="true"} name="Clergy61" value="Traditional" onChange={handleChange} />
      <label htmlFor="option2">Traditional<span class="required"></span> </label>
    </div>
    <div className="col-12 col-sm-6">
      <input type="checkbox" id="option3"  checked={props.inputs.clergy.Clergy62 && props.inputs.clergy.Clergy62=="true"} name="Clergy62" value="Blended" onChange={handleChange} />
      <label htmlFor="option3">Blended<span class="required"></span> </label>
    </div>
    <div className="col-12 col-sm-6">
      <input type="checkbox" id="option4"  checked={props.inputs.clergy.Clergy63 && props.inputs.clergy.Clergy63=="true"} name="Clergy63" value="liturgical" onChange={handleChange} />
      <label htmlFor="option4">Liturgical<span class="required"></span> </label>
    </div>
    <div className="col-12 col-sm-6">
      <input type="checkbox" id="option5"  checked={props.inputs.clergy.Clergy64 && props.inputs.clergy.Clergy64=="true"} name="Clergy64" value="charismatic" onChange={handleChange} />
      <label htmlFor="option5">Charismatic<span class="required"></span> </label>
    </div>
    <div className="col-12 col-sm-6">
      <input type="checkbox" id="option6"   name="Clergy65" checked={props.inputs.clergy.Clergy65 && props.inputs.clergy.Clergy65=="true"} value="others" onChange={handleChange} />
      <label htmlFor="option6">Other<span class="required"></span> </label>
    </div>
  </div>
</div>


      

    
    </div>
  );
};
export default PastorFormPageFour;



