import React from 'react';
import ReactDOM from 'react-dom';
import NotificationSystem from 'react-notification-system';
 
export default class NotificationReact extends React.Component {
  notificationSystem = React.createRef();
  // notificationButton = React.createRef();


  addNotification = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;
    if(this.props.color && this.props.color==='red'){
    notification.addNotification({
      message: this.props.text,
      level: 'error',
      autoDismiss: 10,
      position: 'tc',
      title: this.props.title,
    });
  }
  if(this.props.color && this.props.color==='green'){
    notification.addNotification({
      message: this.props.text,
      level: 'success',
      autoDismiss: 10,
      position: 'tc',
      title: this.props.title,
    });
  }
  };
componentDidMount(){
  // this.props.buttonRef.current.click();
}
  render() {
    return (
      <div>
        <button ref={this.props.buttonRef} style={{visibility:"hidden"}} onClick={this.addNotification}>Add notification</button>
        <NotificationSystem ref={this.notificationSystem} />
      </div>
    );
  }
}