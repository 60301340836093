import React, {useRef, useState,useEffect,useMemo } from "react";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import YearPicker from "react-year-picker";
import ImageUploading from "react-images-uploading";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const RankingComparison = (props) => {
      const [clergyData, setClergyData] = React.useState();
      const [churchData, setChurchData] = React.useState();
      const [clergy ,setClergy] = React.useState([]);
      const [church, setChurch] = React.useState({church:[]});
      useEffect(() => {


            console.log("clergyData,churchData2",clergy,church,churchData)

        
           
          }, [church,clergy,churchData]);
      const handleChange = (e) => {
    
            if (e.target.name.includes("Pastor_ID")) { 
              let id=parseInt( e.target.value);
              let filteredValue=clergy.filter(result=>result.ClergyId==id);   
              setClergyData( filteredValue[0]);

            }
            if (e.target.name.includes("Church_ID")) {
                  let id=parseInt( e.target.value);
         
                  let filteredValue=church.church_leadership_ranking.filter(result=>result.Church_ID==id);  
                  const rankingObject = {};
                  console.log("church",filteredValue)
                  filteredValue.forEach((obj) => {
  rankingObject[obj.LeaderShipSkill] = obj.Ranking;
}); 
for (const key in rankingObject) {
      if (rankingObject.hasOwnProperty(key)) {
        const value = rankingObject[key];
        if (value.includes('%%')) {
          const newValue = value.split('%%')[1];
          rankingObject[key] = newValue;
        }
      }
    }
                  console.log( rankingObject);
                  setChurchData(rankingObject)
            }
      }
      // Church_ID Church_Name  church   Clergy1 clergy ClergyId
      useEffect(() => {


            const getChurchData = async () =>{
              try {
                const res = await axios.get(`${process.env.REACT_APP_URL}/api/churchFormData/getChurchDataForList/`, { withCredentials: true });  
          
       console.log("getChurchDataForList",res.data)
       setChurch(res.data)
  
              } catch (err) {
                console.log("ListSubmissionserr",err);
                toast(err);
              }
            }
            getChurchData();
        
           
          }, []);

          useEffect(() => {


            const getClergyData = async () =>{
              try {
                const res = await axios.get(`${process.env.REACT_APP_URL}/api/clergyFormData/getClergyDataForList`, { withCredentials: true });  
                console.log("res.data.clergy",res.data.clergy);
                setClergy(res.data.clergy)

              } catch (err) {
                console.log("ListSubmissionsPastorerr",err);
                toast(err);
              }
            }
            getClergyData();
        
           
          }, []);


 
  const defaultMaterialTheme = createTheme();
  return (
               <div   >
            


   

        <div className="row" style={{margin:"1px"}}>  

    <div className="col">
    <label>Select Church To View Rankings:<span class="required"></span> </label>
    <select className="form-select"  onChange={handleChange} aria-label="Default select example"name="Church_ID" style={{height: "35px"}}  >
     <option selected value="" disabled>Select Church</option>
 
     { church.church.map((result)=>(<option  value={result.Church_ID} >{result.Church_Name}</option>))}

  </select><br/>
  {( churchData ) && (   
        <table className="table" >
  <thead>
    <tr>
      {/* <th scope="col">#</th> */}
      <th scope="col">Rank</th>
      <th scope="col" style={{width:"25%"}}>Leadership Skill</th>
      <th scope="col">Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      <td  style={{
    width: "15%"
}}> <input name="input1" required type="number" value={churchData.input1||''} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Leadership & Vision-Casting</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Serving as the leader of the leaders in the congregation, the person who articulates the vision and focuses the work of the whole congregation on that vision.
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">2</th>
      <td  style={{
    width: "15%"
}}> <input name="input2" required type="number" value={churchData.input2||''} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Planning & Leading Worship</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Planning and conducting worship services, Preparing computer information for services, and working with others who lead and participate in worship.
</td>
    </tr>
   
    <tr>
      <th style={{display:"none"}} scope="row">3</th>
      <td  style={{
    width: "15%"
}}> <input name="input3" required type="number" value={churchData.input3||''} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Community Leader</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Serving as a volunteer leader in the community to help make the community stronger and our world a better place (Kiwanis, Rotary, Optimus, town/city/county boards, etc.)
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">4</th>
      <td  style={{
    width: "15%"
}}> <input name="input4" required type="number" value={churchData.input4||''} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Staff Supervision</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Overseeing the staff of the church, setting priorities, and di- recting the ministry leaders of the church.
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="input5" required type="number" value={churchData.input5||''} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Discipleship</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Helping others to identify their own special call to service, and enabling them to respond, by developing and fol- lowing a discipline of Bible and other devotional study, partici- pating in programs of continuing education and service to the Kingdom through their giftedness.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="input6" required type="number" value={churchData.input6||''} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Teaching</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Planning and teaching short-term classes, teachingbible studies, teaching confirmation classes, teaching training classes on leadership, evangelism, etc.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="input7" required type="number" value={churchData.input7||''} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Discipleship</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Helping others to identify their own special call to service, and enabling them to respond, by developing and fol- lowing a discipline of Bible and other devotional study, partici- pating in programs of continuing education and service to the Kingdom through their giftedness..</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">6</th>
      <td  style={{
    width: "15%"
}}> <input name="input8" required type="number" value={churchData.input8||''} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Prophetic Ministry</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Speaking the truth of scripture into the culture and community and seeking to lead the community in making a Kingdom difference in the world.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">7</th>
      <td  style={{
    width: "15%"
}}> <input name="input9" required type="number" value={churchData.input9||''} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Office Management</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Keeping accurate records, collecting data, and filling out needed reports</td>
    </tr>
 
   

  <tr>
      <th style={{display:"none"}} scope="row">8</th>
      <td  style={{
    width: "15%"
}}> <input name="input10" required type="number" value={churchData.input10||''} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Administration</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Serving as the executive of the congregation, working with the committees on planning and implementation of programs</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">9</th>
      <td  style={{
    width: "15%"
}}> <input name="input11" required type="number" value={churchData.input11||''} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Communications</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Handling the correspondence of the church, channeling com- munications, getting information into bulletins, newsletters, onto the internet, etc.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">10</th>
      <td  style={{
    width: "15%"
}}> <input name="input12" required type="number" value={churchData.input12||''} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Evangelism</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Calling on the unchurched, nominally churched, de-churched, and people in the community, calling on prospective new members, and generally bearing witness to the Good News in hopes that individuals will accept the Gospel Message.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">11</th>
      <td  style={{
    width: "15%"
}}> <input name="input13" required type="number" value={churchData.input13||''} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Counseling & Spiritual Direction</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Meeting with individuals on a one-on-one basis to work through personal and spiritual problems, helping them to put together a spiritual plan, meeting with couples planning to be married, or those who are dealing with the issues of life.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">12</th>
      <td  style={{
    width: "15%"
}}> <input name="input14" required type="number" value={churchData.input14||''} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Preaching</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Serving as the leader of the leaders in the congregation, the person who articulates the vision and focuses the work of the whole congregation on that vision.
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">13</th>
      <td  style={{
    width: "15%"
}}> <input name="input15" required type="number" value={churchData.input15||''} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Visitation</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Calling on those associated with the church family in their homes or at their places of work in a systematic program to meet each member where they are most comfortable.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">14</th>
      <td  style={{
    width: "15%"
}}> <input name="input16" required type="number" value={churchData.input16||''} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Financial Planning & Fundraising</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Helping to plan the financial program of the church, creating and executing a plan to provide funding for the church and/or any special project or general ministry need.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">15</th>
      <td  style={{
    width: "15%"
}}> <input name="input17" required type="number" value={churchData.input17||''} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Healer</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Builds bridges and restores relationships by creating a healthy environment, with models healthy work/life balance with spiritual and emotional maturity. </td>
    </tr>

  </tbody>
</table>
   )}
 
</div>
<div className="col">
<label>Select Clergy To View Rankings:<span class="required"></span> </label>
    <select className="form-select"  onChange={handleChange} aria-label="Default select example"name="Pastor_ID" style={{height: "35px"}}  >
     <option selected value="" disabled>Select Clergy</option>
     { clergy.map((result)=>(<option  value={result.ClergyId} >{result.Clergy1}</option>))}
  
  </select><br/>
  {( clergyData ) && (   
<table className="table" >
  <thead>
    <tr>
      {/* <th scope="col">#</th> */}
      <th scope="col">Rank</th>
      <th scope="col" style={{width:"25%"}}>Leadership Skill</th>
      <th scope="col">Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy33" required type="number" value={parseInt(clergyData.Clergy33)} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Leadership & Vision-Casting</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Serving as the leader of the leaders in the congregation, the person who articulates the vision and focuses the work of the whole congregation on that vision.
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">2</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy34" required type="number" value={clergyData.Clergy34||""} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Planning & Leading Worship</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Planning and conducting worship services, Preparing computer information for services, and working with others who lead and participate in worship.
</td>
    </tr>
   
    <tr>
      <th style={{display:"none"}} scope="row">3</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy35" required type="number" value={clergyData.Clergy35||""} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Community Leader</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Serving as a volunteer leader in the community to help make the community stronger and our world a better place (Kiwanis, Rotary, Optimus, town/city/county boards, etc.)
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">4</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy36" required type="number" value={clergyData.Clergy36||""} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Staff Supervision</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Overseeing the staff of the church, setting priorities, and di- recting the ministry leaders of the church.
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy37" required type="number" value={clergyData.Clergy37||""} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Discipleship</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Helping others to identify their own special call to service, and enabling them to respond, by developing and fol- lowing a discipline of Bible and other devotional study, partici- pating in programs of continuing education and service to the Kingdom through their giftedness.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy38" required type="number" value={clergyData.Clergy38||""} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Teaching</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Planning and teaching short-term classes, teachingbible studies, teaching confirmation classes, teaching training classes on leadership, evangelism, etc.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy39" required type="number" value={clergyData.Clergy39||""} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Discipleship</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Helping others to identify their own special call to service, and enabling them to respond, by developing and fol- lowing a discipline of Bible and other devotional study, partici- pating in programs of continuing education and service to the Kingdom through their giftedness..</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">6</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy40" required type="number" value={clergyData.Clergy40||""} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Prophetic Ministry</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Speaking the truth of scripture into the culture and community and seeking to lead the community in making a Kingdom difference in the world.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">7</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy41" required type="number" value={clergyData.Clergy41||""} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Office Management</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Keeping accurate records, collecting data, and filling out needed reports</td>
    </tr>
 
   

  <tr>
      <th style={{display:"none"}} scope="row">8</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy42" required type="number" value={clergyData.Clergy42||""} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Administration</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Serving as the executive of the congregation, working with the committees on planning and implementation of programs</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">9</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy43" required type="number" value={clergyData.Clergy43||""} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Communications</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Handling the correspondence of the church, channeling com- munications, getting information into bulletins, newsletters, onto the internet, etc.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">10</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy44" required type="number" value={clergyData.Clergy44||""} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Evangelism</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Calling on the unchurched, nominally churched, de-churched, and people in the community, calling on prospective new members, and generally bearing witness to the Good News in hopes that individuals will accept the Gospel Message.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">11</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy45" required type="number" value={clergyData.Clergy45||""} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Counseling & Spiritual Direction</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Meeting with individuals on a one-on-one basis to work through personal and spiritual problems, helping them to put together a spiritual plan, meeting with couples planning to be married, or those who are dealing with the issues of life.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">12</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy46" required type="number" value={clergyData.Clergy46||""} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Preaching</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Serving as the leader of the leaders in the congregation, the person who articulates the vision and focuses the work of the whole congregation on that vision.
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">13</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy47" required type="number" value={clergyData.Clergy47||""} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Visitation</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Calling on those associated with the church family in their homes or at their places of work in a systematic program to meet each member where they are most comfortable.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">14</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy48" required type="number" value={clergyData.Clergy48||""} min="1" max="17"className="form-control"style={{width:"100%"}} /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Financial Planning & Fundraising</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Helping to plan the financial program of the church, creating and executing a plan to provide funding for the church and/or any special project or general ministry need.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">15</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy49" required type="number" value={clergyData.Clergy49||""} min="1" max="17"className="form-control"style={{width:"100%"}}  /></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Healer</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Builds bridges and restores relationships by creating a healthy environment, with models healthy work/life balance with spiritual and emotional maturity. </td>
    </tr>

  </tbody>
</table>
   )}
</div>

</div>


      

    
    </div>
  );
};
export default RankingComparison;



