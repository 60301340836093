
import React, { useState,useEffect } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';
import ChurchFormPageOne from './ChurchFormPageOne';
import ChurchFormPageTwo from './ChurchFormPageTwo';
import FinancialFormPageOne from './FinancialFormPageOne';
import ChurchFormPageThree from './ChurchFormPageThree';
import { useHistory } from 'react-router-dom';

export const ChurchFormEditTabs = () => {
  const history = useHistory();
  const [fillActive, setFillActive] = useState('tab1');

  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };

  return (
    <><ToastContainer />
     <h3 >Church Edit/Display Form</h3> <br/> 
      <MDBTabs fill className='mb-3' id="editTabHeader">
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab1')} active={fillActive === 'tab1'}>
            Fill General Data
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab2')} active={fillActive === 'tab2'}>
            Fill Questions
          </MDBTabsLink>
        </MDBTabsItem>
   
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab4')} active={fillActive === 'tab4'}>
            Fill Ranking
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab3')} active={fillActive === 'tab3'}>
            Fill Financial Data
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent id="editTabContent">
        <MDBTabsPane show={fillActive === 'tab1'}><ChurchFormPageOne/></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab2'}><ChurchFormPageTwo/></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab4'}><ChurchFormPageThree/></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab3'}><FinancialFormPageOne/></MDBTabsPane>
    
      </MDBTabsContent>
    </>
  );
}
