
import React, { useState } from "react";


import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';

const ChurchFormPageTwoAdd = (props) => {
  let submissions= JSON.parse(localStorage.getItem("submissions"))
  const history = useHistory();
   


    const handleChange = (e) => {
      if (e.target.name.includes("question")) {
        let combinedString = e.target.name + "%%" + e.target.value;
        props.setInputs((prev) => ({ ...prev, [e.target.name]: combinedString}));
      }
      else{
        props.setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
      console.log(props.inputs)
    };

  

  return (
               <div  style={{margin:"3%"}}   >
            <h3 style={{display:"none"}}>CHURCH CONSULTATION QUESTIONS</h3> <br/>       
    
    <div className="row" style={{margin:"1px"}}>  
    <>
    <div className="form-group">
    <label for="exampleFormControlTextarea1">1.	On a scale of 1-10 (10=most healthy), how healthy is church?<span class="required"></span> </label>
    <textarea required  className="form-control" id="q"  name="question1"  rows="2" onChange={handleChange}></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">2.	Describe the community (mission field) in which your church is located and how you perceive your ministry setting?<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="question2"  rows="2" onChange={handleChange}></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">3.	What is your identity as a church?  How would you describe your church to someone who was a newcomer in your community?<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="question3"  rows="2" onChange={handleChange}></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">4.	What are the qualities of your church that you most value?<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="question4"  rows="2" onChange={handleChange}></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">
5.	What is your passion as a church?
<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="question5"  rows="2" onChange={handleChange}></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">6.	What are the strengths of your church?<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="question6"  rows="2" onChange={handleChange}></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">7.	What are the weaknesses of your church?<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="question7"  rows="2" onChange={handleChange}></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">8.	Who are the people in your community (mission field) who are not being reached?<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="question8"  rows="2" onChange={handleChange}></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">9.	What are the opportunities within the community (mission field) for mission and ministry?<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="question9"  rows="2" onChange={handleChange}></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">10.	What do you believe is God’s vision for the future of this congregation? <span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="question10"  rows="2" onChange={handleChange}></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">11.	What is the main thing that needs to change or be developed for this congregation to be highly vital?<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="question11"  rows="2" onChange={handleChange}></textarea>
  </div><br/>

  <div className="form-group">
    <label for="exampleFormControlTextarea1">12.	How are the laity engaged in fulfilling the mission and vision of the congregation? <span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="question12"  rows="2" onChange={handleChange}></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">13.	What other needs or concerns do you have as you look to the future? <span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="question13"  rows="2" onChange={handleChange}></textarea>
  </div><br/>
 
  
  </>
  </div>
  <div style={{textAlign:"center"}} >
  <br/>
 {/* <input type="submit" className="btn btn-success" value="Next" /> */}
           
          </div>





    </div>
  );
};
export default ChurchFormPageTwoAdd;
