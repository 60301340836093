
import React, { useState,useEffect } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import PastorFormAdd from './PastorFormAdd';
import PastorFormPageTwoAdd from './PastorFormPageTwoAdd';
import PastorFormPageThreeAdd from './PastorFormPageThreeAdd';
import PastorFormPageFourAdd from './PastorFormPageFourAdd';
import PastorFormPageFiveAdd from './PastorFormPageFiveAdd';


export const PastorFormAddTabs = () => {
 
    

  useEffect(() => {
    console.log("navFlag3",localStorage.getItem("navFlag"))
    if(localStorage.getItem("navFlag")=="true"){
      // const timer = setTimeout(() => {
        history.push('/churchpageone');
      // }, 1000);
  
    }
 
  }, []);
  const history = useHistory();
  const formData = new FormData();
  const [inputs, setInputs] = useState({});
  const [imageData, setImageData] = useState({});
  const [fillActive, setFillActive] = useState('tab1');
  const churchFormApiCall = async () => {
    function checkValuesInRange(obj) {
      for (var i = 33; i <= 49; i++) {
        var propertyName = "Clergy" + i;
        if (obj.hasOwnProperty(propertyName)) {
          var value = parseInt(obj[propertyName]);
          if (value > 17 || value < 1) {
            return true;
          }
        }
      }
      return false;
    }

    // if(checkValuesInRange(inputs)){
    //   toast("Ranking from 'Page 3' section should not be greater than 17 or less than 1")
    //   return;
    // }
    function hasDuplicateValues(obj) {
      var valuesTracker = {};
    
      for (var i = 33; i <= 49; i++) {
        var propertyName = "Clergy" + i;
        if (obj.hasOwnProperty(propertyName)) {
          var value = obj[propertyName];
          if (valuesTracker[value]) {
            return true;
          } else {
            valuesTracker[value] = true;
          }
        }
      }
      
      return false;
    }
    

    
    if(hasDuplicateValues(inputs)){
      toast("Ranking from 'Page 3' section should have distinct values")
      return;
    }

   
  
    try {
      console.log("inputs844",(inputs));
      let filez;
      if(imageData.Snapshot_current_Membership){
filez=imageData.Snapshot_current_Membership;
delete imageData.Snapshot_current_Membership;

      }

      const res = await axios.post(`${process.env.REACT_APP_URL}/api/clergyFormData/`, inputs, { withCredentials: true });  
      console.log("Snapshot_current_Membership",(res.data));
      if(imageData.isImage==true){
      
  
        formData.append('image', filez.file);
        formData.append('id', (res.data));
        var response = await axios.post(`${process.env.REACT_APP_URL}/api/uploadClergyImage/`, formData, { withCredentials: true });  

      }
      toast('Form Submitted Successfully');
    
      setTimeout(function () {
        // window.location.replace('/admin/ListSubmissions');
        history.push('/admin/ListSubmissionspastor');
      }, 2000);

    } catch (err) {
      console.log("churchFormData",err);
      toast(err);
    }
  }
  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };

  return (
    <><ToastContainer />
      <MDBTabs fill className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab1')} active={fillActive === 'tab1'}>
          page 1
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab2')} active={fillActive === 'tab2'}>
          page 2
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab4')} active={fillActive === 'tab4'}>
          page 3
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab3')} active={fillActive === 'tab3'}>
          page 4
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab5')} active={fillActive === 'tab5'}>
          page 5
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>
      <form  onSubmit={(e) => { e.preventDefault(); churchFormApiCall(); console.log("gsd");}} >
      <MDBTabsContent>
        <MDBTabsPane show={fillActive === 'tab1'}><PastorFormAdd inputs={inputs} setInputs={setInputs} formData={formData} setImageData={setImageData}/></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab2'}><PastorFormPageTwoAdd inputs={inputs} setInputs={setInputs}/></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab4'}><PastorFormPageThreeAdd inputs={inputs} setInputs={setInputs}/></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab3'}><PastorFormPageFourAdd inputs={inputs} setInputs={setInputs}/></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab5'}><PastorFormPageFiveAdd inputs={inputs} setInputs={setInputs}/></MDBTabsPane>
      </MDBTabsContent>
      <br/>
      <div style={{textAlign:"center"}} >
   
         
   <button type="submit"
               className="btn btn-success" >Submit</button>   <p>Please make sure to fill required fields from all tabs before submitting</p>
   </div> 
   </form> 
    </>
  );
}
