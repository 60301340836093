import React, {useRef, useState,useEffect,useMemo } from "react";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import YearPicker from "react-year-picker";
import ImageUploading from "react-images-uploading";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const PastorFormPageFourAdd = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [awa, setAwa] = useState([]); 
  let awaObject={};
 
  const columns = useMemo(
    () => [
   
      {
        field: 'year',
        title: 'Year',
 
        //size: 40,
     
      },
      {
        field: 'percentage',
        title: 'Attendance Percentage',
     
        //size: 20,
    
      },   ],
      [],
    );



  useEffect(() => {
    let obj={...props.inputs};
    obj.awa=awa;
    console.log(typeof obj,obj)
    props.setInputs(obj);

   }, [awa]);

  const [err, setError] = useState(null);

  const handleChange = (e) => {
    if (e.target.name.includes("question")) {
      let combinedString = e.target.name + "%%" + e.target.value;
      props.setInputs((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else if(e.target.name.includes("input")){
      let combinedString = e.target.name + "%%" + e.target.value;
      props.setInputs((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else{
   

    props.setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
    if(e.target.type=="checkbox"){
      console.log("checkbox",e.target.checked)
      props.setInputs((prev) => ({ ...prev, [e.target.name]:e.target.checked}));
    }
    console.log("inputs2",props.inputs)
  };
  const inputDate = (e) => {
    awaObject.year=e;
  };
  const inputAttendance = (e) => {
    awaObject.percentage=e.target.value;
      };
    
  const handleSubmit = async (e) => {
    console.log("handleSubmit55")
    e.preventDefault();
    window.location.replace('/churchpagetwo');
  };
  const defaultMaterialTheme = createTheme();
  return (
               <div     >
                <br/>  <br/>  <br/>


   

        <div className="row" style={{margin:"1px"}}>  
    <div className="col">
        <table className="table" >
  <thead>
    <tr>
      {/* <th scope="col">#</th> */}
      <th scope="col" style={{width:"40%"}}>Skill</th>
      <th scope="col" >Rating</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      {/* <td  style={{
    width: "15%"
}}> 
<input name="input1" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td> */}

     
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Biblical: Faithful to the biblical text 

</td> 
 {<td  style={{ 
    width: "15%"
}}> 
<input name="Clergy50" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td> }

    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">2</th>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Wesleyan: Consistent with Wesleyan theology</td>
      <td  style={{
    width: "15%"
}}> 
<input name="Clergy51" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
      {/* <td style={{
fontStyle: "italic",fontSize:"small"}}>Planning and conducting worship services, Preparing computer information for services, and working with others who lead and participate in worship.
</td> */}
    </tr>
   
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Engaging: Gets and keeps people's attention</td>
      <th style={{display:"none"}} scope="row">3</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy52" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
      
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Relevant: Be relevant to the daily life of the congregation</td>
      
      <th style={{display:"none"}} scope="row">4</th>
      <td  style={{
    width: "15%"
}}>
     <input name="Clergy53" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Relational: Winsome, authentic and approachable pulpit presence</td>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy54" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
    
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Grounded: Speaks out of a deep and authentic spiritual life</td>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> 
<input name="Clergy55" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Eloquent: Skillful use of humor, stories and other communication tools</td>
      <th style={{display:"none"}} scope="row">6</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy56" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Deepening: Draws people into a deeper relationship with God. </td>
      <th style={{display:"none"}} scope="row">7</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy57" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
     
    </tr>
 
   
  </tbody>
</table>

 
</div>
<div className="col">
        <table className="table" >
  <thead>
    <tr>
      {/* <th scope="col">#</th> */}
      <th scope="col" style={{width:"40%"}}>Skill</th>
      <th scope="col" >Rating</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      {/* <td  style={{
    width: "15%"
}}> 
<input name="input1" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td> */}

     
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Focused: Clear and focused on what the preacher wants people to know, feel and do
</td>
 {<td  style={{
    width: "15%"
}}> 
<input name="Clergy58" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td> }

    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Evangelistic: Inspires, motivates and invites people to make a transformative relationship with Jesus Christ.</td>
      <th style={{display:"none"}} scope="row">2</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy59" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
     
    </tr>
   
    
    
 
   
  </tbody>
</table>

 
</div>

  {/* <div className="form-group">
    <label for="exampleFormControlTextarea1">1.	What other critical pastoral gifts and skills are needed to help you move toward that vision? <span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question15"  rows="1"   onChange={handleChange}></textarea>
  </div> */}
</div>

<div className="form-group">
  <label>
  With what styles of worship do you feel comfortable/competent?
  <span class="required"></span> </label>
  <br />
  <div className="row" style={{margin:"1px"}}>
    <div className="col-12 col-sm-6">
      <input type="checkbox" id="option1" name="Clergy60" value="contemporary" onChange={handleChange} />
      <label htmlFor="option1">contemporary <span class="required"></span> </label>
    </div>
    <div className="col-12 col-sm-6">
      <input type="checkbox" id="option2" name="Clergy61" value="Traditional" onChange={handleChange} />
      <label htmlFor="option2">Traditional<span class="required"></span> </label>
    </div>
    <div className="col-12 col-sm-6">
      <input type="checkbox" id="option3" name="Clergy62" value="Blended" onChange={handleChange} />
      <label htmlFor="option3">Blended<span class="required"></span> </label>
    </div>
    <div className="col-12 col-sm-6">
      <input type="checkbox" id="option4" name="Clergy63" value="Liturgical" onChange={handleChange} />
      <label htmlFor="option4">Liturgical<span class="required"></span> </label>
    </div>
    <div className="col-12 col-sm-6">
      <input type="checkbox" id="option5" name="Clergy64" value="Charismatic" onChange={handleChange} />
      <label htmlFor="option5">Charismatic<span class="required"></span> </label>
    </div>
    <div className="col-12 col-sm-6">
      <input type="checkbox" id="option6" name="Clergy65" value="Other" onChange={handleChange} />
      <label htmlFor="option6">Other<span class="required"></span> </label>
    </div>
  </div>
</div>


      

    
    </div>
  );
};
export default PastorFormPageFourAdd;



