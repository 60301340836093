
import React, { useState,useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import axios from "axios";
const ChurchFormPageTwo = () => {
  

  const history = useHistory();
  useEffect(() => {
    console.log("navFlag68",localStorage.getItem("navFlag"))  
  if(localStorage.getItem("navFlag")=="true"){
  history.push("/admin/ListSubmissions");

  }

}, []);
  let Church_ID=parseInt(localStorage.getItem("Church_ID"));

  const [inputs, setInputs] = useState({church_ID:parseInt(Church_ID)});
    const [inputsEdit, setInputsEdit] = useState({church_ID:Church_ID});
    let initialValue={};
    const churchFormApiCall2 = async () => {
      try {
    
        const res = await axios.post(`${process.env.REACT_APP_URL}/api/churchFormData/update`, inputsEdit, { withCredentials: true });  
        toast('Form Updated Successfully');
        localStorage.removeItem("submissionsEdit");
        setTimeout(function () {
          history.push('/admin/ListSubmissions');
        }, 2000);
    
      } catch (err) {
        console.log("churchFormData",err);
        toast(err);
      }
    }
    useEffect(() => {
      let Church_ID=parseInt(localStorage.getItem("Church_ID"));
      const getChurchData = async () =>{
        try {
          const res = await axios.get(`${process.env.REACT_APP_URL}/api/churchFormData/get/${Church_ID}`, { withCredentials: true });  
       console.log(res.data)
      initialValue.church=(res.data.church.filter(a=>a.Church_ID==Church_ID))[0];
      delete initialValue.church.Snapshot_current_Membership;
          initialValue.church_awa_in_person=res.data.church_awa_in_person.filter(a=>a.Church_ID==Church_ID);
          initialValue.church_leadership_ranking=res.data.church_leadership_ranking.filter(a=>a.Church_ID==Church_ID);
          initialValue.financialform=res.data.financialform.filter(a=>a.Church_ID==Church_ID);
          initialValue.churchQuestion=res.data.church_question.filter(a=>a.Church_ID==Church_ID);
  
          function getAllProperties(obj) {
            const result = {};
          
            function traverse(obj, path) {
              for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                  const value = obj[key];
                  const newPath = path ? `${path}_${key}` : key;
          
                  if (typeof value === 'object' && value !== null) {
                    traverse(value, newPath);
                  } else {
                    result[newPath] = value;
                  }
                }
              }
            }
          
            traverse(obj, '');
          
            return result;
          }
          
          // Example usage:
          
          
          const properties = getAllProperties(initialValue);
          console.log("initialValue",initialValue)
       
          for (let prop in properties) {
      
            if (typeof properties[prop]=="string" && properties[prop].includes("%%")) {
              let substrings = properties[prop].split("%%");
              properties[substrings[0]] = substrings[1];
            }
          }
          console.log("properties342",properties);
          setInputs(properties);
        } catch (err) {
          console.log("ListSubmissionserr",err);
          toast(err);
        }
      }
      getChurchData();
  
     
    }, []);

  const handleChange = (e) => {
    if (e.target.name.includes("question")) {
      let combinedString = e.target.name + "%%" + e.target.value;
      setInputsEdit((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else{
      setInputsEdit((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
    console.log(inputsEdit)
  };
  const handleSubmit = async (e) => {
 
    
    localStorage.setItem("submissionsEdit", JSON.stringify(inputsEdit)); 

  
  };
  return (
               <div  style={{margin:"3%"}}   >
            <h3 style={{display:"none"}}>CHURCH CONSULTATION QUESTIONS</h3> <br/>       
    <form method="post" action=""    >
    <div className="row" style={{margin:"1px"}}>  
    <>
    <div className="form-group">
    <label for="exampleFormControlTextarea1">1.	On a scale of 1-10 (10=most healthy), how healthy is church?<span class="required"></span> </label>
    <textarea  className="form-control" id="q"  name="question1"  rows="2" placeholder={inputs.question1||""} onChange={handleChange} ></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">2.	Describe the community (mission field) in which your church is located and how you perceive your ministry setting?<span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question2"  rows="2" placeholder={inputs.question2||""} onChange={handleChange} ></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">3.	What is your identity as a church?  How would you describe your church to someone who was a newcomer in your community?<span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question3"  rows="2" placeholder={inputs.question3||""} onChange={handleChange} ></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">4.	What are the qualities of your church that you most value?<span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question4"  rows="2" placeholder={inputs.question4||""} onChange={handleChange} ></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">
5.	What is your passion as a church?
<span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question5"  rows="2" placeholder={inputs.question5||""} onChange={handleChange} ></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">6.	What are the strengths of your church?<span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question6"  rows="2" placeholder={inputs.question6||""} onChange={handleChange} ></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">7.	What are the weaknesses of your church?<span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question7"  rows="2" placeholder={inputs.question7||""} onChange={handleChange} ></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">8.	Who are the people in your community (mission field) who are not being reached?<span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question8"  rows="2" placeholder={inputs.question8||""} onChange={handleChange} ></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">9.	What are the opportunities within the community (mission field) for mission and ministry?<span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question9"  rows="2" placeholder={inputs.question9||""} onChange={handleChange} ></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">10.	What do you believe is God’s vision for the future of this congregation? <span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question10"  rows="2" placeholder={inputs.question10||""} onChange={handleChange} ></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">11.	What is the main thing that needs to change or be developed for this congregation to be highly vital?<span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question11"  rows="2" placeholder={inputs.question11||""} onChange={handleChange} ></textarea>
  </div><br/>

  <div className="form-group">
    <label for="exampleFormControlTextarea1">12.	How are the laity engaged in fulfilling the mission and vision of the congregation? <span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question12"  rows="2" placeholder={inputs.question12||""} onChange={handleChange} ></textarea>
  </div><br/>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">13.	What other needs or concerns do you have as you look to the future? <span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question13"  rows="2" placeholder={inputs.question13||""} onChange={handleChange} ></textarea>
  </div><br/>
 
  
  </>
  </div>
  <div style={{textAlign:"center"}} >
  <br/>

           
          </div>
</form>
<div style={{textAlign:"center"}} >
  <br/>
  <button onClick={() => {
  

   churchFormApiCall2();
    
     
     

}} className="btn btn-success" >
           Update
         </button>
          </div>


    </div>
  );
};
export default ChurchFormPageTwo;
