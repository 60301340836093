
import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

import PastorFormPageFive from './PastorFormPageFive';
import PastorFormPageFour from './PastorFormPageFour';
import PastorFormPageThree from './PastorFormPageThree';
import PastorFormPageTwo from './PastorFormPageTwo';
import PastorFormAdd from './PastorFormAdd';
import PastorFormPageOne from './PastorFormPageOne';


export const PastorFormEditTabs = () => {
  const history = useHistory();
  const [fillActive, setFillActive] = useState('tab1');
  const [inputs, setInputs] = useState({clergy:{},appointment:[],children:[],onlineworship:[],education:[],inperson:[]});
  const [edit, setEdit] = useState({});
  const [rankingObject, setRankingObject] = useState({});
  useEffect(() => {
    console.log("Edit/Display",localStorage.getItem("navFlag"))  
  if(localStorage.getItem("navFlag")=="true"){
  history.push("/churchpagetwo");

  }

}, []);
  useEffect(() => {
 var obj={...edit}
 obj.ClergyId=inputs.clergy.ClergyId;
    setEdit(obj)
       }, [inputs.clergy.ClergyId]);
  const [imageData, setImageData] = useState();
  console.log("editobj11",edit,rankingObject)
let initialValue={};
const pastorFormApiCall = async () => {

  function checkValuesInRange(obj,min,max,limit) {
    for (var i = min; i <= max; i++) {
      var propertyName = "Clergy" + i;
      if (obj.hasOwnProperty(propertyName)) {
        var value = parseInt(obj[propertyName]);
        if (value > limit || value < 1) {
          return true;
        }
      }
    }
    return false;
  }

  if(checkValuesInRange(rankingObject,33,49,17)){
    toast("Ranking from 'Page 3' section should not be greater than 17 or less than 1")
    return;
  }
  if(checkValuesInRange(rankingObject,17,32,10)){
    toast("Ranking from 'Page 2' section should not be greater than 10 or less than 1")
    return;
  }
  if(checkValuesInRange(rankingObject,50,65,10)){
    toast("Ranking from 'Page 4' section should not be greater than 10 or less than 1")
    return;
  }
  function hasDuplicateValues(obj) {
    var valuesTracker = [];
    
    for (var i = 33; i <= 49; i++) {
      var propertyName = "Clergy" + i;
      if (obj.hasOwnProperty(propertyName)) {
        var value = obj[propertyName];
        
        // Ignore empty values
        if (value !== "") {
          if (valuesTracker.includes(value)) {
            return true;
          } else {
            valuesTracker.push(value);
          }
        }
      }
    }
    
    return false;
  }
  
  if(hasDuplicateValues(rankingObject)){
    toast("Ranking from 'Page 3' section should have distinct values")
    return;
  }



  let filez;
  try {
   
   
    // formData.append('image', edit.Snapshot_current_Membership.file);
    if(edit.Snapshot_current_Membership){
    filez=edit.Snapshot_current_Membership;
    delete edit.Snapshot_current_Membership;
  }
    // formData.append('edit', (edit));
    const res = await axios.post(`${process.env.REACT_APP_URL}/api/clergyFormData/update`, edit, { withCredentials: true });  
    toast('Form Updated Successfully');
    localStorage.removeItem("submissionsEdit");
    setTimeout(function () {
      history.push('/admin/ListSubmissionspastor');
    }, 2000);
    if(edit.isImage==true){
      
      const formData = new FormData();
      formData.append('image', filez.file);
      formData.append('id', (inputs.clergy.ClergyId));
      var response = await axios.post(`${process.env.REACT_APP_URL}/api/updateClergyImage/`, formData, { withCredentials: true });  

    }
  } catch (err) {
    console.log("churchFormData",err);
    toast(err);
  }
}
  useEffect(() => {
    let ClergyId=parseInt(localStorage.getItem("ClergyId"));
    const getClergyData = async () =>{
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/clergyFormData/get/${ClergyId}`, { withCredentials: true });  
     console.log(res.data)
        initialValue.clergy=(res.data.clergy.filter(a=>a.ClergyId==ClergyId))[0];
        setRankingObject(initialValue.clergy);
        initialValue.appointment=res.data.appointment.filter(a=>a.ClergyId==ClergyId);
        initialValue.education=res.data.education.filter(a=>a.ClergyId==ClergyId);
        initialValue.inperson=res.data.inperson.filter(a=>a.ClergyId==ClergyId);
        initialValue.onlineworship=res.data.onlineworship.filter(a=>a.ClergyId==ClergyId);
        initialValue.children=res.data.children.filter(a=>a.ClergyId==ClergyId);
        let church=res.data.church.filter(a=>a.Pastor_ID==ClergyId)
       if(church.length>0){

        initialValue.churchName= church[0].Church_Name;
       }
       else{
        initialValue.churchName= "";
       }
        console.log("res.data,initialValue",res.data,initialValue)
        setInputs(initialValue);
      } catch (err) {
        console.log("ListSubmissionserr",err);
        toast(err);
      }
    }
    getClergyData();

   
  }, []);

  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };

  return (
    <><ToastContainer />
         <h3 >Pastor Edit/Display Form</h3> <br/> 
      <MDBTabs fill className='mb-3' id="editTabHeader">
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab1')} active={fillActive === 'tab1'}>
          Page 1
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab2')} active={fillActive === 'tab2'}>
          Page 2
          </MDBTabsLink>
        </MDBTabsItem>
   
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab4')} active={fillActive === 'tab4'}>
          Page 3
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab3')} active={fillActive === 'tab3'}>
          Page 4
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleFillClick('tab5')} active={fillActive === 'tab5'}>
           Page 5
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent id="editTabContent">
        <MDBTabsPane show={fillActive === 'tab1'}><PastorFormPageOne inputs={inputs} setInputs={setInputs} setEdit={setEdit} edit={edit}/></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab2'}><PastorFormPageTwo inputs={inputs} setInputs={setInputs} setEdit={setEdit} setRankingObject={setRankingObject}/></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab4'}><PastorFormPageThree inputs={inputs} setInputs={setInputs} setEdit={setEdit} setRankingObject={setRankingObject}/></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab3'}><PastorFormPageFour inputs={inputs} setInputs={setInputs} setEdit={setEdit} setRankingObject={setRankingObject}/></MDBTabsPane>
        <MDBTabsPane show={fillActive === 'tab5'}><PastorFormPageFive inputs={inputs} setInputs={setInputs} setEdit={setEdit}/></MDBTabsPane>
    
      </MDBTabsContent>
      <div style={{textAlign:"center"}} >
  <br/>
  <button onClick={() => {
  

   pastorFormApiCall();
    
     
     

}} className="btn btn-success" >
           Update
         </button>
          </div>
    </>
  );
}
