
import React, {useRef, useState,useEffect,useMemo } from "react";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import YearPicker from "react-year-picker";
import ImageUploading from "react-images-uploading";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
let flag=0;
const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const ChurchFormPageThree = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [awa, setAwa] = useState([]); 
  let awaObject={};
  const [rankingObject, setRankingObject] = useState({});
  const columns = useMemo(
    () => [
   
      {
        field: 'year',
        title: 'Year',
 
        //size: 40,
     
      },
      {
        field: 'percentage',
        title: 'Attendance Percentage',
     
        //size: 20,
    
      },   ],
      [],
    );
    const churchFormApiCall1 = async () => {
      console.log("rankingObject25",rankingObject)
      function checkValuesInRange(obj) {
        for (var i = 1; i <= 17; i++) {
          var propertyName = "input" + i;
          if (obj.hasOwnProperty(propertyName)) {
            var value = parseInt(obj[propertyName]);
            if (value > 17 || value < 1) {
              return true;
            }
          }
        }
        return false;
      }
    
      if(checkValuesInRange(rankingObject)){
        toast("Ranking from 'Fill Ranking' section should not be greater than 17 or less than 1")
        return;
      }
      function hasDuplicateValues(obj) {
        var valuesTracker = [];
        
        for (var i = 1; i <= 17; i++) {
          var propertyName = "input" + i;
          if (obj.hasOwnProperty(propertyName)) {
            var value = obj[propertyName];
            
            // Ignore empty values
            if (value !== "") {
              if (valuesTracker.includes(value)) {
                return true;
              } else {
                valuesTracker.push(value);
              }
            }
          }
        }
        
        return false;
      }
      
      if(hasDuplicateValues(rankingObject)){
        toast("Ranking from 'Fill Ranking' section should have distinct values")
        return;
      }
      try {
    
        const res = await axios.post(`${process.env.REACT_APP_URL}/api/churchFormData/update`, inputsEdit, { withCredentials: true });  
        toast('Form Updated Successfully');
        localStorage.removeItem("submissionsEdit");
        setTimeout(function () {
          console.log('Form Updated Successfully4')
          history.push('/admin/ListSubmissions');
        }, 2000);
    
      } catch (err) {
        console.log("churchFormData",err);
        toast(err);
      }
    }
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  const [inputs, setInputs] = useState({});

  // console.log("valuesAfterPercent18",valuesAfterPercent)



  const [inputsEdit, setInputsEdit] = useState({});

 let initialValue={};
 let Church_ID;  

  useEffect(() => {
   localStorage.removeItem("submissions")

  
  }, []);
  useEffect(() => {
    Church_ID=parseInt(localStorage.getItem("Church_ID"));
    const getChurchData = async () =>{
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/churchFormData/get/${Church_ID}`, { withCredentials: true });  
     console.log(res.data)
        initialValue.church=(res.data.church.filter(a=>a.Church_ID==Church_ID))[0];
        delete initialValue.church.Snapshot_current_Membership;
        initialValue.church_awa_in_person=res.data.church_awa_in_person.filter(a=>a.Church_ID==Church_ID);
        initialValue.church_leadership_ranking=res.data.church_leadership_ranking.filter(a=>a.Church_ID==Church_ID);
        initialValue.financialform=res.data.financialform.filter(a=>a.Church_ID==Church_ID);
        initialValue.churchQuestion=res.data.church_question.filter(a=>a.Church_ID==Church_ID);

        function getAllProperties(obj) {
          const result = {};
        
          function traverse(obj, path) {
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                const newPath = path ? `${path}_${key}` : key;
        
                if (typeof value === 'object' && value !== null) {
                  traverse(value, newPath);
                } else {
                  result[newPath] = value;
                }
              }
            }
          }
        
          traverse(obj, '');
        
          return result;
        }
        
        // Example usage:
        
        
        const properties = getAllProperties(initialValue);
        console.log("initialValue",initialValue)
     
        for (let prop in properties) {
  
          if (typeof properties[prop]=="string" && properties[prop].includes("%%")) {
            let substrings = properties[prop].split("%%");
            properties[substrings[0]] = substrings[1];
          }
        }
        console.log("properties342",properties);

        setInputs(properties);
        setRankingObject(properties);
        setInputsEdit((prev) => ({ ...prev, church_ID: properties.church_Church_ID }));
 
      } catch (err) {
        console.log("ListSubmissionserr",err);
        toast(err);
      }
    }
    getChurchData();

   
  }, []);
  useEffect(() => {
    let obj={...inputs};
    obj.awa=awa;
    console.log(typeof obj,obj)
    setInputs(obj);

   }, [awa]);

  const [err, setError] = useState(null);

  const handleChange = (e) => {
    setRankingObject((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if (e.target.name.includes("question")) {
      let combinedString = e.target.name + "%%" + e.target.value;
      setInputsEdit((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else if(e.target.name.includes("input")){
      let combinedString = e.target.name + "%%" + e.target.value;
      setInputsEdit((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else{
   
    console.log(e.target.name,e.target.value)
    setInputsEdit((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    }
    console.log("inputs2",inputsEdit,rankingObject)
  };
  const inputDate = (e) => {
    awaObject.year=e;
  };
  const inputAttendance = (e) => {
    awaObject.percentage=e.target.value;
      };
    
  const handleSubmit = async (e) => {
    console.log("handleSubmit55")
    e.preventDefault();
    window.location.replace('/churchpagetwo');
  };
  const defaultMaterialTheme = createTheme();
  return (
               <div     >
                <br/>  <br/>  <br/>


   

        <div className="row">  
    <div className="col">
        <table className="table" >
  <thead>
    <tr>
      {/* <th scope="col">#</th> */}
      <th scope="col">Rank</th>
      <th scope="col" style={{width:"25%"}}>Leadership Skill</th>
      <th scope="col">Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      <td  style={{
    width: "15%"
}}> <input name="input1" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input1||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Leadership & Vision-Casting</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Serving as the leader of the leaders in the congregation, the person who articulates the vision and focuses the work of the whole congregation on that vision.
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">2</th>
      <td  style={{
    width: "15%"
}}> <input name="input2" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input2||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Planning & Leading Worship</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Planning and conducting worship services, Preparing computer information for services, and working with others who lead and participate in worship.
</td>
    </tr>
   
    <tr>
      <th style={{display:"none"}} scope="row">3</th>
      <td  style={{
    width: "15%"
}}> <input name="input3" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input3||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Community Leader</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Serving as a volunteer leader in the community to help make the community stronger and our world a better place (Kiwanis, Rotary, Optimus, town/city/county boards, etc.)
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">4</th>
      <td  style={{
    width: "15%"
}}> <input name="input4" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input4||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Staff Supervision</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Overseeing the staff of the church, setting priorities, and di- recting the ministry leaders of the church.
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="input5" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input5||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Discipleship</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Helping others to identify their own special call to service, and enabling them to respond, by developing and fol- lowing a discipline of Bible and other devotional study, partici- pating in programs of continuing education and service to the Kingdom through their giftedness.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="input16" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input16||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Teaching</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Planning and teaching short-term classes, teachingbible studies, teaching confirmation classes, teaching training classes on leadership, evangelism, etc.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">6</th>
      <td  style={{
    width: "15%"
}}> <input name="input6" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input6||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Prophetic Ministry</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Speaking the truth of scripture into the culture and community and seeking to lead the community in making a Kingdom difference in the world.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">6</th>
      <td  style={{
    width: "15%"
}}> <input name="input17" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input17||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Personal & Spiritual Growth</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Developing and following a discipline of Bible Study and other devotional study, participating in programs of personal development and education, in order to lead more effectively.  </td>  </tr>
    <tr>
      <th style={{display:"none"}} scope="row">7</th>
      <td  style={{
    width: "15%"
}}> <input name="input7" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input7||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Office Management</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Keeping accurate records, collecting data, and filling out needed reports</td>
    </tr>
 
   
  </tbody>
</table>

 
</div>
<div className="col">
        <table className="table" >
  <thead>
    <tr>
      {/* <th scope="col">#</th> */}
      <th scope="col">Rank</th>
      <th scope="col">Leadership Skill</th>
      <th scope="col">Description</th>
    </tr>
  </thead>
  <tbody>
  <tr>
      <th style={{display:"none"}} scope="row">8</th>
      <td  style={{
    width: "15%"
}}> <input name="input8" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input8||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Administration</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Serving as the executive of the congregation, working with the committees on planning and implementation of programs</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">9</th>
      <td  style={{
    width: "15%"
}}> <input name="input9" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input9||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Communications</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Handling the correspondence of the church, channeling com- munications, getting information into bulletins, newsletters, onto the internet, etc.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">10</th>
      <td  style={{
    width: "15%"
}}> <input name="input10" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input10||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Evangelism</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Calling on the unchurched, nominally churched, de-churched, and people in the community, calling on prospective new members, and generally bearing witness to the Good News in hopes that individuals will accept the Gospel Message.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">11</th>
      <td  style={{
    width: "15%"
}}> <input name="input11" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input11||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Counseling & Spiritual Direction</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Meeting with individuals on a one-on-one basis to work through personal and spiritual problems, helping them to put together a spiritual plan, meeting with couples planning to be married, or those who are dealing with the issues of life.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">12</th>
      <td  style={{
    width: "15%"
}}> <input name="input12" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input12||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Preaching</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Serving as the leader of the leaders in the congregation, the person who articulates the vision and focuses the work of the whole congregation on that vision.
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">13</th>
      <td  style={{
    width: "15%"
}}> <input name="input13" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input13||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Visitation</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Calling on those associated with the church family in their homes or at their places of work in a systematic program to meet each member where they are most comfortable.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">14</th>
      <td  style={{
    width: "15%"
}}> <input name="input14" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input14||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Financial Planning & Fundraising</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Helping to plan the financial program of the church, creating and executing a plan to provide funding for the church and/or any special project or general ministry need.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">15</th>
      <td  style={{
    width: "15%"
}}> <input name="input15" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  placeholder={inputs.input15||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Healer</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Builds bridges and restores relationships by creating a healthy environment, with models healthy work/life balance with spiritual and emotional maturity. </td>
    </tr>

  </tbody>
</table>

</div>
<div className="form-group">
    <label for="exampleFormControlTextarea1">2.	What personality traits of a clergy leader best match the culture of this local church?
<span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question14"  rows="1" placeholder={inputs.question14||""}  onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">1.	What other critical pastoral gifts and skills are needed to help you move toward that vision? <span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question15"  rows="1" placeholder={inputs.question15||""}  onChange={handleChange}></textarea>
  </div>
</div>

<div style={{textAlign:"center"}} >
  <br/>
  <button onClick={() => {
  

   churchFormApiCall1();
    
     
     

}} className="btn btn-success" >
           Update
         </button>
          </div>
      

    
    </div>
  );
};
export default ChurchFormPageThree;



