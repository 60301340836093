import React, {useRef, useState,useEffect,useMemo } from "react";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import YearPicker from "react-year-picker";
import ImageUploading from "react-images-uploading";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const PastorFormPageTwoAdd = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [awa, setAwa] = useState([]); 
  let awaObject={};
 
  const columns = useMemo(
    () => [
   
      {
        field: 'year',
        title: 'Year',
 
        //size: 40,
     
      },
      {
        field: 'percentage',
        title: 'Attendance Percentage',
     
        //size: 20,
    
      },   ],
      [],
    );
 



  useEffect(() => {
    let obj={...props.inputs};
    obj.awa=awa;
    console.log(typeof obj,obj)
    props.setInputs(obj);

   }, [awa]);

  const [err, setError] = useState(null);

  const handleChange = (e) => {
    if (e.target.name.includes("question")) {
      let combinedString = e.target.name + "%%" + e.target.value;
      props.setInputs((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else if(e.target.name.includes("input")){
      let combinedString = e.target.name + "%%" + e.target.value;
      props.setInputs((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else{
   
    console.log(e.target.name,e.target.value)
    props.setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
    console.log("inputs2",props.inputs)
  };
  const inputDate = (e) => {
    awaObject.year=e;
  };
  const inputAttendance = (e) => {
    awaObject.percentage=e.target.value;
      };
    
  const handleSubmit = async (e) => {
    console.log("handleSubmit55")
    e.preventDefault();
    window.location.replace('/churchpagetwo');
  };
  const defaultMaterialTheme = createTheme();
  return (
               <div     >
                <br/>  <br/>  <br/>


   

        <div className="row" style={{margin:"1px"}}>  
    <div className="col">
        <table className="table" >
  <thead>
    <tr>
      {/* <th scope="col">#</th> */}
      <th scope="col" style={{width:"40%"}}>Skill</th>
      <th scope="col" >Rating</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      {/* <td  style={{
    width: "15%"
}}> 
<input name="input1" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td> */}

     
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Ability to communicate faith and beliefs through
Inspirational preaching based on scripture

</td>
 {<td  style={{
    width: "15%"
}}> 
<input name="Clergy17" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td> }

    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">2</th>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Strategic leadership and thinking – ability to assist congregation in the development of a ministry plan</td>
      <td  style={{
    width: "15%"
}}> 
<input name="Clergy18" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
      {/* <td style={{
fontStyle: "italic",fontSize:"small"}}>Planning and conducting worship services, Preparing computer information for services, and working with others who lead and participate in worship.
</td> */}
    </tr>
   
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Community engagement leading congregation to an outward focus</td>
      <th style={{display:"none"}} scope="row">3</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy19" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
      
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Pastoral Care</td>
      
      <th style={{display:"none"}} scope="row">4</th>
      <td  style={{
    width: "15%"
}}>
     <input name="Clergy20" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Evangelistic: sharing faith, invitational, following up with visitors, equipping congregation for task of evangelism</td>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy21" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
    
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Ability to lead a team approach to ministry</td>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> 
<input name="Clergy22" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Ability to lead congregation in developing intentional discipleship pathways</td>
      <th style={{display:"none"}} scope="row">6</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy23" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Emphasis on Biblical teaching for congregation</td>
      <th style={{display:"none"}} scope="row">7</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy24" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
     
    </tr>
 
   
  </tbody>
</table>

 
</div>
<div className="col">
        <table className="table" >
  <thead>
    <tr>
      {/* <th scope="col">#</th> */}
      <th scope="col" style={{width:"40%"}}>Skill</th>
      <th scope="col" >Rating</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      {/* <td  style={{
    width: "15%"
}}> 
<input name="input1" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td> */}

     
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Encourages and works with children, youth and young adult ministries

</td>
 {<td  style={{
    width: "15%"
}}> 
<input name="Clergy25" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td> }

    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Promotes a variety of worship settings/services</td>
      <th style={{display:"none"}} scope="row">2</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy26" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
     
    </tr>
   
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Administration and staff development</td>
      <th style={{display:"none"}} scope="row">3</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy27" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
    
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Ability to Lead Change</td>
      <th style={{display:"none"}} scope="row">4</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy28" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
    
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Develop laity for ministry</td>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy29" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Stewardship development</td>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy30" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
      
     
    </tr>
    <tr>
    <td style={{
fontStyle: "italic",fontSize:"small"}}>Self-care</td>
      <th style={{display:"none"}} scope="row">6</th>
      <td  style={{
    width: "15%"
}}> <input name="Clergy31" required type="number" min="1" max="10" className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
     
    
    </tr>
    
 
   
  </tbody>
</table>

 
</div>
<div className="form-group">
    <label for="exampleFormControlTextarea1">1.	In your years of ministry, what experiences do you especially celebrate? What experiences have challenged you? What is your passion in the life of the church?

?
<span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="Clergy32"  rows="1"   onChange={handleChange}></textarea>
  </div>
  {/* <div className="form-group">
    <label for="exampleFormControlTextarea1">1.	What other critical pastoral gifts and skills are needed to help you move toward that vision? <span class="required"></span> </label>
    <textarea className="form-control" id="q"  name="question15"  rows="1"   onChange={handleChange}></textarea>
  </div> */}
</div>


      

    
    </div>
  );
};
export default PastorFormPageTwoAdd;



