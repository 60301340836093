import React, {useRef, useState,useEffect,useMemo } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import YearPicker from "react-year-picker";
import ImageUploading from "react-images-uploading";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import axios from "axios";

let flag=0;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  const PastorFormPageOne = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    const [show, setShow] = useState(false);
    const [awa, setAwa] = useState([]); 
    const [aws, setAws] = useState([]); 
    const [awb, setAwb] = useState([]);
    const [edu, setEdu] = useState([]);
    const [appointmentHistory, setAppointmentHistory] = useState([]);
    let awaObject={};
    let awsObject={};
    let awbObject ={};
    let eduobject = {Education3:"No"};
    let appointmentobject = {};
    const [img, setImg] = useState(); 
    const appointmentColumn = useMemo(
      () => [
     
        {
          field: 'Appointment1',
          title: 'From',
          
          //size: 40,
        
        },
        {
          field: 'Appointment2',
          title: 'To',
       
          //size: 20,
        }, 
        {
          field: 'Appointment3',
          title: 'Church',
       
          //size: 20,
        }, 
       
      ],
        [],
      );
    const InEdu = useMemo(
      () => [
     

        {
          field: 'Education2',
          title: 'Degree name',
       
          //size: 20,
        }, 
        {
          field: 'Education1',
          title: 'Year Passed',
          
          //size: 40,
        
        },
        {
          field: 'Education3',
          title: 'Is Graduation Level',
       
          //size: 20,
        }, 
      ],
        [],
      );

    const Inperson = useMemo(
      () => [
     
        {
          field: 'Inperson1',
          title: 'Year',
          
          //size: 40,
        
        },
        {
          field: 'Inperson2',
          title: 'attendance',
       
          //size: 20,
        },   ],
        [],
      );
      const Onperson = useMemo(
        () => [
       
          {
            field: 'OnlineWorship1',
            title: 'Year',
            
            //size: 40,
          
          },
          {
            field: 'OnlineWorship2',
            title: 'attendance',
         
            //size: 20,
          },   ],
          [],
        );
  
    const columns = useMemo(
      () => [
     
        {
          field: 'Children1',
          title: 'Name',
          
          //size: 40,
        
        },
        {
          field: 'Children2',
          title: 'Age',
       
          //size: 20,
        },   ],
        [],
      );
    const [images, setImages] = React.useState([]);
    const maxNumber = 69;
    const onChange = (imageList, addUpdateIndex) => {
      console.log("imageList, addUpdateIndex",imageList, addUpdateIndex);
      //  document.getElementById("pastorImg").style.display="none"
      setImages([imageList]);
      var obj={...props.edit}
      obj.Snapshot_current_Membership=imageList[0];
      obj.isImage=true;
      props.setEdit(obj)
 
   
    };
   
    useEffect(() => {


   
      if(props.inputs.children){
      setAwa( props.inputs.children);
      }
  
      if(props.inputs.inperson){
        setAws( props.inputs.inperson);
        }
        if(props.inputs.onlineworship){
          setAwb( props.inputs.onlineworship);
          }
          if(props.inputs.education){
            setEdu( props.inputs.education);
            }
            if(props.inputs.appointment){
              setAppointmentHistory( props.inputs.appointment);

              }
              setImg(props.inputs.clergy.Snapshot_current_Membership);

            



    }, [props.inputs]);
    console.log("props.inputs.churchName",props.inputs.churchName,props.inputs)
    useEffect(() => {
  let obj={...props.edit};
     obj.awa=awa;
      console.log(typeof obj,obj)
      props.setEdit(obj);
  
     }, [awa]);
     useEffect(() => {

      let obj={...props.edit};
      obj.aws=aws;

      props.setEdit(obj);
  
     }, [aws]);

     useEffect(() => {
      let obj={...props.edit};
      obj.awb=awb;

      props.setEdit(obj);
      
     }, [awb]);

     useEffect(() => {
      let obj={...props.edit};
      obj.edu=edu;
 
      props.setEdit(obj);
      for (const key in eduobject) {
        if (eduobject.hasOwnProperty(key)) {
          delete eduobject[key];
        }
      }
      eduobject.Education3="No";    
      const inputs = document.querySelectorAll('input[name*="Education"]');

// Iterate over the input fields and reset their values
inputs.forEach((input) => {
if (input.type === 'checkbox') {
  input.checked = false;
} else {
  input.value = '';
}
});
     }, [edu]);
     useEffect(() => {
      let obj={...props.edit};
      obj.appointment=appointmentHistory;
 
      props.setEdit(obj);
  
     }, [appointmentHistory]);
     
  
    const [err, setError] = useState(null);
    const [churchData, setChurchData] = useState([]); 
    const handleAppointment = (e) => {
appointmentobject[e.target.name]=e.target.value;

    }
    useEffect(() => {
      try {
 
        const getPastorData = async () =>{
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/churchFormData/getChurchDataForList/`, { withCredentials: true }); 
        setChurchData(res.data.church)
        }
              getPastorData();
      } catch (err) {
       // console.log("ListSubmissionserr",err);
        toast(err);
      }
     
    }, []);
    const bufferToBase64 = (buffer) => {
      const base64String = Buffer.from(buffer).toString('base64');
      return `data:image/jpeg;base64,${base64String}`;
    };
    const handleEducation= (e) => {
    
            if(e.target.name=="Education3"){
             if( e.target.checked==false){
              eduobject[e.target.name]="No"
             }
             else{
              eduobject[e.target.name]="Yes"
             }
            }
      
            else{
            eduobject[e.target.name]=e.target.value;
            }
            // seteduObjLength(Object.keys(eduobject).length)
              
                }
  
    const handleChange = (e) => {
      console.log(e.target.name,e.target.value)
      props.setEdit((prev) => ({ ...prev, [e.target.name]: e.target.value }));
     

 
    };

    const inpersondate = (e) => {
      awsObject.Inperson1=e;
    };
    const Inpersondata = (e) => {
      awsObject.Inperson2 = e.target.value;
    }

    const onpersondate = (e) => {
      awbObject.OnlineWorship1=e;
    };

    const onpersondata = (e) => {
      awbObject.OnlineWorship2 = e.target.value;
    }
    const inputAttendance = (e) => {
  
      awaObject[e.target.name]=e.target.value;
      
     
        };


    const defaultMaterialTheme = createTheme();
    return (
                 <div >

      <div className="row" style={{margin:"1px"}}>  
      <div className="col">
      <div className="form-element">
      <label>Clergy Name:<span class="required"></span> </label>
                        <input required type="text" placeholder={props.inputs.clergy.Clergy1} className="form-control" name="Clergy1" onChange={handleChange}/>
      </div><br/>
      <div className="form-element">
      <label>Clergy Last Name	:<span class="required"></span> </label>
      <input required type="text" className="form-control" placeholder={props.inputs.clergy.Clergy111}  name="Clergy111" onChange={handleChange}/>
      </div><br/> 
      {( img ) && ( <img src={bufferToBase64(img.data)}  width="170"/> )}

      <div className="form-element">
      <label>Email:<span class="required"></span> </label>
                        <input type="email" placeholder={props.inputs.clergy.Clergy2||""} className="form-control" name="Clergy2" onChange={handleChange}/>
      </div><br/>
      <div className="form-element">
      <label>Cell:<span class="required"></span> </label>
                        <input type="number" className="form-control" placeholder={props.inputs.clergy.Clergy3} name="Clergy3" onChange={handleChange}/>
      </div><br/>
   
     <div className="form-element">
      <label>Social Media Link:<span class="required"></span> </label>
                        <input type="text" className="form-control" placeholder={props.inputs.clergy.Clergy4} name="Clergy4" onChange={handleChange}/>
      </div><br/>
      <div className="form-group">

      <label for="exampleFormControlTextarea1">LinkedIn profile:<span class="required"></span> </label>
                        <textarea className="form-control" id="Mission_Statement" placeholder={props.inputs.clergy.Clergy5}  name="Clergy5" rows="2" onChange={handleChange}></textarea>
    </div><br/>
   
 
        <br/>
        <div className="row" style={{margin:"1px"}}> 
      <label>Spouse Name:<span class="required"></span> </label> 
      <div className="form-group">
                            <input type="text" className="form-control" min="1" placeholder={props.inputs.clergy.Clergy16} max="100" name="Clergy16" onChange={handleChange}/>
      </div>
      <label>Children living in the home and their ages:<span class="required"></span> </label> <br/>
      <div className="col">
                            <input type="text" className="form-control" min="1" placeholder={props.inputs.Children1}  max="100" name="Children1" onChange={inputAttendance}/>
      </div> 
      <div className="col">
                            <input type="number" className="form-control" min="1" placeholder={props.inputs.Children2}  max="100"name="Children2" onChange={inputAttendance}/>
      </div> 
      <div className="col">
      <button onClick={() => {
        var arr=[...awa];

        arr.push(awaObject);
                setAwa(arr);
               
                console.log("awa",props.inputs);
       
              }} className="btn btn-success">Add Child</button>     
               </div> 
      </div> <br/>
 
                      <ThemeProvider theme={defaultMaterialTheme}>
           <MaterialTable
            columns={columns}
            data={awa}
            title="Children"
            options={{
              filtering: true,sorting: true
            }}
          /></ThemeProvider>
  
      <div className="row" style={{margin:"1px"}}> 
      <label>Average In-Person Worship Attendance:<span class="required"></span> </label> 
    <div className="col">
                            <YearPicker name="Inperson1" placeholder={props.inputs.Inperson1} onChange={inpersondate} /> 
      </div> 
      <div className="col">
                            <input type="number" className="form-control" min="1" placeholder={props.inputs.Inperson2}  max="100"name="Inperson2" onChange={Inpersondata}/>
      </div> 
      <div className="col">
      <button onClick={() => {
        var arr=[...aws];
console.log("awsObject",awsObject)
        arr.push(awsObject);
                setAws(arr);
              }} className="btn btn-success">Add  Attendance</button>      </div> 
      </div> <br/>
      <ThemeProvider theme={defaultMaterialTheme}>
           <MaterialTable
            columns={Inperson}
            data={aws}
            title="Average In-Person  Attendance"
            options={{
              filtering: true,sorting: true
            }}
          /></ThemeProvider>
<hr></hr>
<div className="row" style={{margin:"1px"}}> 
      <label><b>Educational Information:</b><span class="required"></span> </label> 
     
    <div className="col">
       <label>Year Passed<span class="required"></span> </label>
      <input type="date" className="form-control"  name="Education1" onChange={handleEducation} placeholder="Graduation Year" /> 
      </div>  
      <div className="col">
      <label>Degree Name<span class="required"></span> </label>
      <input type="text" className="form-control" min="1" placeholder="Degree Name"  max="100"name="Education2" onChange={handleEducation}/>
      </div>  
  
      <div className="row" style={{margin:"1px"}}>
      <div className="col" >
      <br/>
      <input type="checkbox" id="option1" name="Education3"  onChange={handleEducation} />
      <label htmlFor="option1">  Is Graduation Level<span class="required"></span> </label>
      </div>
      
     
      <div className="col">
      <br/>
     
      
      <button onClick={() => {
        var arr=[...edu];
console.log("eduobject22",eduobject,arr)
        arr.push(eduobject);
 
        setEdu(arr);

             
       
              }} className="btn btn-success">Add Education</button>  
           {/* {( eduObjLength>1) && (   )}  */ }
                 </div> 
                 </div>
                 <br/>     <br/>
          <ThemeProvider theme={defaultMaterialTheme}>
            <br/>
           <MaterialTable
            columns={InEdu}
            data={edu}
            title="Education Info"
            options={{
              filtering: true,sorting: true
            }}
          /></ThemeProvider>
           </div>
    
    
    </div> 
    <div className="col">
      <label>Martial Status :<span class="required"></span> </label>
                    <select id="martialStatus" className="form-select" aria-label="Default select example" checked="{props.inputs.clergy.Clergy6}" name="Clergy6" onChange={handleChange}>
    <option value="Married">Married</option>
    <option value="UnMarried">UnMarried</option>
   
    </select><br/>
      <div className="form-element">
      <label>Specific Family Needs:<span class="required"></span> </label>
                        <input type="text" className="form-control" placeholder={props.inputs.clergy.Clergy7} name="Clergy7" onChange={handleChange}/>
      </div><br/>
      
     
      <div className="form-element">
      <label>Years in Current Appointment:<span class="required"></span> </label>
                        <input type="text" className="form-control" placeholder={props.inputs.clergy.Clergy8} name="Clergy8" onChange={handleChange}/>
      </div><br/>
      <div className="form-element">
      <label>Total Years in Ordained or Licensed Ministry:<span class="required"></span> </label>
                        <input type="text" className="form-control" placeholder={props.inputs.clergy.Clergy9}  name="Clergy9" onChange={handleChange}/>
      
      </div><br/>
      <div className="form-element">
      <label>Current Pastoral Compensation:<span class="required"></span> </label>
                        <input type="text" className="form-control"  name="Clergy10" placeholder={props.inputs.clergy.Clergy10} onChange={handleChange}/>
                        <input type="text" className="form-control" placeholder={props.inputs.clergy.Clergy11} name="Clergy11" onChange={handleChange}/>
                        <input type="text" className="form-control" placeholder={props.inputs.clergy.Clergy12} name="Clergy12" onChange={handleChange}/>
      </div><br/>
   
  
      <div className="row" style={{margin:"1px"}}> 
      <div className="form-group">
      <label>Asssigned Church: <span><input type="text" name="Church_Name"  className="form-control"  value={props.inputs.churchName|| ""}/></span><span class="required"></span> </label>
      
     
      </div>
      <label for="exampleFormControlTextarea1">Appointment History:<span class="required"></span> </label>
    <div className="col">
                                <input type="date" name="Appointment1" placeholder={props.inputs.Appointment1} className="form-control"   onChange={handleAppointment}/>
    </div>
    <div className="col">
                                <input type="date" name="Appointment2" className="form-control" placeholder={props.inputs.Appointment2}   onChange={handleAppointment}/>
    </div>  
    </div>
    <div className="row" style={{margin:"1px"}}> 
    <div className="col">
    <div className="form-group"  style={{height:"80%"}}> 
    <select className="form-select" aria-label="Default select example" style={{}} name="Appointment3"  onChange={handleAppointment}>
     <option selected value="" disabled>Select From Existing Church</option>
     { churchData.map((result)=>(<option  value={result.Church_Name} >{"id:"+result.Church_ID+" name:"+result.Church_Name}</option>))}
  </select>
  </div>   </div>
  <div className="col">
    <div className="form-group" style={{height:"130%"}}> 
    <input type="text" name="Appointment3" className="form-control" placeholder="Or Add New Church Name"   onChange={handleAppointment}/>

    </div>
 
    </div>

    </div>
    <div className="col">
    <button onClick={() => {
        var arr=[...appointmentHistory];

        arr.push(appointmentobject);
                setAppointmentHistory(arr);
               
            
       
              }} className="btn btn-success">Add Appointment History</button>     
 </div>


    
  
 <ThemeProvider theme={defaultMaterialTheme}>
           <MaterialTable
            columns={appointmentColumn}
            data={appointmentHistory}
            title="Appointment history"
            options={{
              filtering: true,sorting: true
            }}
          /></ThemeProvider>



    <div className="form-group">
      <label for="exampleFormControlTextarea1">Special Trainings:<span class="required"></span> </label>
                        <textarea className="form-control" placeholder={props.inputs.clergy.Clergy15} name="Clergy15" rows="1" onChange={handleChange}></textarea>
    </div>
    <div className="form-group">
      <label for="exampleFormControlTextarea1">Gifts for Ministry:<span class="required"></span> </label>
                        <textarea className="form-control" placeholder={props.inputs.clergy.Clergy13} name="Clergy13" rows="1" onChange={handleChange}></textarea>
    </div>
    <div className="form-group">
      <label for="exampleFormControlTextarea1">Growing edge:<span class="required"></span> </label>
                        <textarea className="form-control" placeholder={props.inputs.clergy.Clergy14} name="Clergy14" rows="1" onChange={handleChange}></textarea>
    </div>
  
    <br/>
    <div className="row" style={{margin:"1px"}}> 
      <label>Average OnLine Attendance:<span class="required"></span> </label> 
    <div className="col">
      <YearPicker name="OnlineWorship1" onChange={onpersondate} /> 
      </div> 
      <div className="col">
                            <input type="number" className="form-control" min="1" placeholder={props.inputs.OnlineWorship2}  max="100"name="OnlineWorship2" onChange={onpersondata}/>
      </div> 
      <div className="col">
      <button onClick={() => {
        var arr=[...awb];
console.log("awbObject",awbObject)
        arr.push(awbObject);
                setAwb(arr);
               
             
       
              }} className="btn btn-success">Add  Attendance</button>      </div> 
      </div> <br/>
      <ThemeProvider theme={defaultMaterialTheme}>
           <MaterialTable
            columns={Onperson}
            data={awb}
            title="Average OnLine  Attendance"
            options={{
              filtering: true,sorting: true
            }}
          /></ThemeProvider>
    
    </div> 
<hr></hr>

   

     
  
    </div>
  <hr></hr>
   
  {/* </form> */}
  


  <br/>

      </div>
    );
  };

  export default PastorFormPageOne;