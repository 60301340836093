import axios from "axios";
import React, {useRef, useState,useEffect } from "react";
import { useHistory } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from '@mui/material/Modal';
const FinancialFormPageOne = () => {
  let submissions= JSON.parse(localStorage.getItem("submissionsEdit"))

  // const navigate = useNavigate();
  const history = useHistory();
  let Church_ID=parseInt(localStorage.getItem("Church_ID"));

  const [inputs, setInputs] = useState({church_ID:parseInt(Church_ID)});
  const [inputsEdit, setInputsEdit] = useState({church_ID:Church_ID});
  let initialValue={};
  useEffect(() => {
    let Church_ID=parseInt(localStorage.getItem("Church_ID"));
    const getChurchData = async () =>{
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/churchFormData/get/${Church_ID}`, { withCredentials: true });  
     console.log(res.data)
     initialValue.church=(res.data.church.filter(a=>a.Church_ID==Church_ID));
     delete initialValue.church.Snapshot_current_Membership;
        initialValue.church_awa_in_person=res.data.church_awa_in_person.filter(a=>a.Church_ID==Church_ID);
        initialValue.church_leadership_ranking=res.data.church_leadership_ranking.filter(a=>a.Church_ID==Church_ID);
        initialValue.financialform=res.data.financialform.filter(a=>a.Church_ID==Church_ID);
        initialValue.churchQuestion=res.data.church_question.filter(a=>a.Church_ID==Church_ID);
  
        function getAllProperties(obj) {
          const result = {};
        
          function traverse(obj, path) {
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                const newPath = path ? `${path}_${key}` : key;
        
                if (typeof value === 'object' && value !== null) {
                  traverse(value, newPath);
                } else {
                  result[newPath] = value;
                }
              }
            }
          }
        
          traverse(obj, '');
        
          return result;
        }
        
        // Example usage:
        
        
        const properties = getAllProperties(initialValue);
        console.log("initialValue",initialValue)
     
        for (let prop in properties) {
      
          if (typeof properties[prop]=="string" && properties[prop].includes("%%")) {
            let substrings = properties[prop].split("%%");
            properties[substrings[0]] = substrings[1];
          }
        }
        console.log("properties342",properties);
        setInputs(properties);
      } catch (err) {
        console.log("ListSubmissionserr",err);
        toast(err);
      }
    }
    getChurchData();

   
  }, []);

  const handleChange = (e) => {
    if (e.target.name.includes("financialField")) {
      let combinedString = e.target.name + "%%" + e.target.value;
      setInputsEdit((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    console.log(inputsEdit)
  };
  const churchFormApiCall4 = async () => {
    try {

      const res = await axios.post(`${process.env.REACT_APP_URL}/api/churchFormData/update`, inputsEdit, { withCredentials: true });  
      toast('Form Updated Successfully');
    
      setTimeout(function () {
        history.push('/admin/ListSubmissions');
      }, 2000);

    } catch (err) {
      console.log("churchFormData",err);
      toast(err);
    }
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
  };
  return (
               <div  style={{margin:"3%"}}   >
            <h3 style={{display:"none"}}>FINANCIAL & COMPENSATION</h3> <br/>       
    <form method="post" action=""   >
    <div className="row" style={{margin:"1px"}}>  
    <div className="col">
    <h4>Financial Overview</h4> <br/>  
  
    <div className="form-element">
    <label>Annual Giving 	:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField17" placeholder={inputs.financialField17||""} onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Budget for Programming:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField18" placeholder={inputs.financialField18||""} onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Budget for Administration:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField19" placeholder={inputs.financialField19||""} onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Total Budget for Staffing:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField20" placeholder={inputs.financialField20||""} onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Annual Budget Total:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField21" placeholder={inputs.financialField21||""} onChange={handleChange}/>
    </div><br/>
    
    <div className="form-element">
    <label>Budget for Trustees/Facilities:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField21" placeholder={inputs.financialField21||""} onChange={handleChange}/>
    </div><br/>
  
  </div>
  <div className="col">
    <h4>Pastoral Compensation</h4> <br/>  
    <div className="form-element">
    <label>Salary:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField23" placeholder={inputs.financialField23||""} onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Travel IRS Rate:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField24" placeholder={inputs.financialField24||""} onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>ARP:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField25" placeholder={inputs.financialField25||""} onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Cont. Ed.:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField26" placeholder={inputs.financialField26||""} onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>HOUSING ALLOWANCE (if no parsonage available):<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField27" placeholder={inputs.financialField27||""} onChange={handleChange}/>
    </div><br/>


    <div >
    <label>State Income Tax:<span class="required"></span> </label>
    <div class="form-check">
     <input checked={inputs.financialField28||""} onChange={handleChange} class="form-check-input" type="radio" name="financialField28" id="exampleRadios1" value="Yes" />
  <label class="form-check-label" for="exampleRadios1">
    Yes
  <span class="required"></span> </label>
</div>
<div class="form-check">
  <input checked={inputs.financialField28||""} onChange={handleChange} class="form-check-input" type="radio" name="financialField28" id="exampleRadios2" value="No"/>
  <label class="form-check-label" for="exampleRadios2">
    No
  <span class="required"></span> </label>
</div>
</div>



<br/>
<div>
    <label>Parsonage:<span class="required"></span> </label>
    <div class="form-check">
   
  <input checked={inputs.financialField29||""} onChange={handleChange} class="form-check-input" type="radio" name="financialField29" id="exampleRadios1" value="Yes"/>
  <label class="form-check-label" for="exampleRadios3">
    Yes
  <span class="required"></span> </label>
</div>

<div class="form-check">
  <input checked={inputs.financialField29||""} onChange={handleChange} class="form-check-input" type="radio" name="financialField29" id="exampleRadios2" value="No"/>
  <label class="form-check-label" for="exampleRadios4">
    No
  <span class="required"></span> </label>
</div>
{( inputsEdit.financialField29 && inputsEdit.financialField29=="financialField29%%No") && (    
  <>
<div className="form-element">
    <label>HOUSING ALLOWANCE (if no parsonage available):<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField27" onChange={handleChange}/>
    </div><br/>
    </> 
)}  
{( inputsEdit.financialField29 && inputsEdit.financialField29=="financialField29%%Yes") && (    
  <>
   <div className="row" style={{margin:"1px"}}>  
<div className="col">
<div className="form-element">
    <label>Bedrooms:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField30" onChange={handleChange}/>
    </div><br/>
</div>
<div className="col">
<div className="form-element">
    <label>Bathrooms:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField31" onChange={handleChange}/>
    </div><br/>
</div>
  </div>
  <div className="row" style={{margin:"1px"}}>  
<div className="col">
<div className="form-element">
    <label>Living Room:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField32" onChange={handleChange}/>
    </div><br/>
</div>
<div className="col">
<div className="form-element">
    <label>Kitchen:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField33" onChange={handleChange}/>
    </div><br/>
</div>
  </div>
  <div className="row" style={{margin:"1px"}}>  
<div className="col">
<div className="form-element">
    <label>Dining Room:<span class="required"></span> </label>
    <input required type="text" className="form-control" name="financialField34" onChange={handleChange}/>
    </div><br/>
</div>
<div className="col">
<div className="form-element">
    <label>Family Room:<span class="required"></span> </label>
    <input required type="text" className="form-control" name="financialField35" onChange={handleChange}/>
    </div><br/>
</div>
  </div>
  <div className="row" style={{margin:"1px"}}>  
<div className="col">
<div className="form-element">
    <label>Garage:<span class="required"></span> </label>
    <input required type="text" className="form-control" name="financialField36" onChange={handleChange}/>
    </div><br/>
</div>
<div className="col">
<div className="form-element">
    <label>Study Office:<span class="required"></span> </label>
    <input required type="text" className="form-control" name="financialField37" onChange={handleChange}/>
    </div><br/>
</div>
  </div>
  <div className="row" style={{margin:"1px"}}>  
<div className="col">
<div className="form-element">
    <label>Dates of Major Renovations:<span class="required"></span> </label>
    <input required type="date" className="form-control" name="financialField38" onChange={handleChange}/>
    </div><br/>
</div>
<div className="col">
<div className="form-element">
    <label>Date of Construction:<span class="required"></span> </label>
    <input required type="date" className="form-control" name="financialField39" onChange={handleChange}/>
    </div><br/>
</div>
  </div>
  <div className="row" style={{margin:"1px"}}>  

<div className="col">
<div className="form-element">
    <label>Comments:<span class="required"></span> </label>
    <input required type="text" className="form-control" name="financialField40" onChange={handleChange}/>
    </div><br/>
</div>
  </div>
</> 
)} 
</div> 
 
  
  </div>
  
  </div>
</form>
<div style={{textAlign:"center"}} >
  <br/>
  <Button onClick={() => {
  

   churchFormApiCall4();
    
     
     

}} className="btn btn-success" >
           Update
         </Button>
          </div>


    </div>
  );
};
export default FinancialFormPageOne;

