import axios from "axios";
import React, {useRef, useState,useEffect,useMemo } from "react";
import MaterialReactTable from 'material-react-table';
import { Delete, Edit ,Download} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
const ListSubmissions = () => {
  const history = useHistory();


  const [show, setShow] = useState(false);
  const [awa, setAwa] = useState([]); 
  let awaObject={};
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteRow = async (Church_ID) => {



    try {
    var  res = await axios.get(`${process.env.REACT_APP_URL}/api/churchFormData/${Church_ID}`, { withCredentials: true });
toast("Row deleted Successfully")
    } catch (err) {
      console.log(err)
    }
  }
  const columns2 = useMemo(
    () => [

      {
        accessorKey: 'Church_Name',
        header: 'Church_Name',
        
        //size: 40,

        }

      
    ],
    [],
  );
  const columns = useMemo(
    () => [
      {
        field: 'Church_ID',
        title: 'Church_ID',
        hidden: true,
     
      },
      {
        field: 'Church_Name',
        title: 'Church Name',
     
      },
      {
        field: 'Address',
        title: 'Address',

    
      },  
      
      {
        field: 'Phone',
        title: 'Phone',

    
      }  ,
      {
        field: 'Email',
        title: 'Email',
     
        //size: 20,
    
      },  
      
      {
        field: 'Pastor_ID',
        title: 'Assigned Pastor',
     
        //size: 20,
    
      },
    ],
      [],
    );
  const [images, setImages] = React.useState([]);
  const [submissions, setSubmissions] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {ls
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

 
  const [inputs, setInputs] = useState({Church_Type:"partial"  
  });
  
  useEffect(() => {
  
    console.log("navFlag1",localStorage.getItem("navFlag"))
    if(localStorage.getItem("navFlag")=="true"){
    history.push('/admin/ListSubmissionspastor');
    }

    const getChurchData = async () =>{
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/churchFormData/getChurchDataForList/`, { withCredentials: true });  
  
  setSubmissions(res.data.church)
  localStorage.setItem("ChurchData", res.data.church); 
      } catch (err) {
        console.log("ListSubmissionserr",err);
        toast(err);
      }
    }
    getChurchData();

   
  }, []);
    
  


  const [err, setError] = useState(null);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    console.log("inputs2",inputs)
  };
  const inputDate = (e) => {
    awaObject.year=e;
setAwa(awa);
console.log(awaObject);
  };
  const inputAttendance = (e) => {

    awaObject.percentage=e.target.value;
    setAwa(awa);
    console.log(awaObject);
      };
    

  const handleSubmit = async (e) => {
    console.log("handleSubmit55")
    e.preventDefault();

    try {
      // const res = await axios.post(`${process.env.REACT_APP_URL}/api/posts/`, inputs, { withCredentials: true });  

    } catch (err) {
    }
  };
  const defaultMaterialTheme = createTheme();
  return (
               <div id = "frm"    >
  <></>
            {/* <h3 style={{display:"none"}}>List of Submission3243s</h3> <br/> */}
            <ToastContainer />  



 
      {( submissions!=null) && (<>
      
           
<ThemeProvider theme={defaultMaterialTheme}>
{/* <MaterialReactTable 
             columns={columns2}
             data={submissions}
           /> */}
         <MaterialTable
          columns={columns}
          data={submissions}
          title="List of Submissions"
          options={{
            filtering: true,sorting: true,exportButton: true
          }}
          actions={[
            {
              icon: () => <Edit />,
              tooltip: "Edit/Display",
              onClick: (event, rowData) => {
                console.log(rowData)
                localStorage.setItem("Church_ID", (rowData.Church_ID)); 
           
                history.push('/churchpageone');
            
              },
            },
            {
              icon: () => <Delete />,
              tooltip: "Delete Row Data",
              onClick: (event, rowData) => {
        
                    const filteredData=submissions.filter(a=>a.Church_ID!=rowData.Church_ID);
           setSubmissions(filteredData)
           deleteRow(rowData.Church_ID);
     

            
            
              },
            },
            {
              icon: () => <Download />,
              tooltip: "Export as PDF",
              onClick: (event, rowData) => {
                console.log("write export code here",rowData.Church_ID);
                const base="church.pdf?p_church_id="+rowData.Church_ID;
                const jasper_username = process.env.REACT_APP_JASPER_USERNAME
                const jasper_password = process.env.REACT_APP_JASPER_PASSWORD
                const jasper_url = process.env.REACT_APP_JASPER_URL
                console.log('JasperURL: ',jasper_url)
          
                return new Promise((resolve, reject) => {
                    axios.get(`${jasper_url}${base}`, {
                      responseType: 'blob',
                      headers: {
                        'Accept': 'application/pdf'
                      },
                      auth: {
                        username: jasper_username,
                        password: jasper_password
                      }
                    }).then((response) => {
                      resolve(response)
                      const blob = new Blob([response.data], { type: 'application/pdf' })
                      const objectUrl = window.URL.createObjectURL(blob)
                      var link = document.createElement('a')
                      link.href = objectUrl
                      link.download = 'church.pdf'
                      link.click()
                      setTimeout(function () {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        window.URL.revokeObjectURL(objectUrl)
                        }, 100)
                  }).catch((error) => {
                      reject(error)
                  })
                })

            
            
              },
            },
          ]}
        /></ThemeProvider>  
        
        </>
         )}
    </div>
  );
};
export default ListSubmissions;
