import axios from "axios";
import React, {useRef, useState,useEffect,useMemo } from "react";

import { Delete, Edit ,Download} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
const ListSubmissionsPastor = () => {
  const history = useHistory();


  const [show, setShow] = useState(false);
  const [awa, setAwa] = useState([]); 
  let awaObject={};
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteRow = async (ClergyId) => {



    try {
      const res = await axios.get(`${process.env.REACT_APP_URL}/api/clergyFormData/${ClergyId}`, { withCredentials: true });
toast("Row deleted Successfully")
    } catch (err) {
      console.log(err)
    }
  }
  const columns = useMemo(
    () => [
      {
        field: 'ClergyId',
        title: 'ClergyId',
        hidden: true,
     
      },
      {
        field: 'Clergy1',
        title: 'Clergy Name',
     
      },
      {
        field: 'Clergy2',
        title: 'Email',

    
      },  
      
      {
        field: 'Clergy3',
        title: 'Phone',

    
      }  ,
      {
        field: 'Clergy9',
        title: 'Years in ministry',
     
        //size: 20,
    
      },  
      
      {
        field: 'Clergy6',
        title: 'Marital Status',
     
        //size: 20,
    
      },
    ],
      [],
    );
  const [images, setImages] = React.useState([]);
  const [submissions, setSubmissions] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {ls
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

 
  const [inputs, setInputs] = useState({Church_Type:"partial" });
  
  useEffect(() => {
    console.log("navFlag2",localStorage.getItem("navFlag"))
    if(localStorage.getItem("navFlag")=="true"){
    history.push('/admin/pastorformadd');
 
    }

    const getChurchData = async () =>{
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/clergyFormData/getClergyDataForList`, { withCredentials: true });  
  console.log("res.data33",res.data)

  setSubmissions(res.data.clergy)
  localStorage.setItem("ClergyData", res.data.clergy); 
      } catch (err) {
        console.log("ListSubmissionsPastorerr",err);
        toast(err);
      }
    }
    getChurchData();

   
  }, []);
    
  


  const [err, setError] = useState(null);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    console.log("inputs2",inputs)
  };
  const inputDate = (e) => {
    awaObject.year=e;
setAwa(awa);
console.log(awaObject);
  };
  const inputAttendance = (e) => {

    awaObject.percentage=e.target.value;
    setAwa(awa);
    console.log(awaObject);
      };
    

  const handleSubmit = async (e) => {
    console.log("handleSubmit55")
    e.preventDefault();

    try {
      // const res = await axios.post(`${process.env.REACT_APP_URL}/api/posts/`, inputs, { withCredentials: true });  

    } catch (err) {
    }
  };
  const defaultMaterialTheme = createTheme();
  return (
               <div id = "frm"    >
  <></>
            {/* <h3 style={{display:"none"}}>List of Submission3243s</h3> <br/> */}
            <ToastContainer />  



 
      {( submissions!=null) && (
<ThemeProvider theme={defaultMaterialTheme}>
         <MaterialTable
          columns={columns}
          data={submissions}
          title="List of Submissions"
          options={{
            filtering: true,sorting: true,exportButton: true
          }}
          actions={[
            {
              icon: () => <Edit />,
              tooltip: "Edit/Display",
              onClick: (event, rowData) => {
                console.log(rowData)
                localStorage.setItem("ClergyId", (rowData.ClergyId)); 
       
                history.push('/clergyeditpage');
            
              },
            },
            {
              icon: () => <Delete />,
              tooltip: "Delete Row Data",
              onClick: (event, rowData) => {
        
                    const filteredData=submissions.filter(a=>a.ClergyId!=rowData.ClergyId);
           setSubmissions(filteredData)
           deleteRow(rowData.ClergyId);            
              },
            },
            {
              icon: () => <Download />,
              tooltip: "Export as PDF",
              onClick: (event, rowData) => {
                console.log("write export code here",rowData.ClergyId);
              
                const jasper_username = process.env.REACT_APP_JASPER_USERNAME
                const jasper_password = process.env.REACT_APP_JASPER_PASSWORD
                const jasper_url = process.env.REACT_APP_JASPER_URL
                console.log('JasperURL: ',jasper_url)
                const base="Clergy.pdf?p_ClergyId="+rowData.ClergyId;
                return new Promise((resolve, reject) => {
                    axios.get(`${jasper_url}${base}`, {
                      responseType: 'blob',
                      headers: {
                        'Accept': 'application/pdf'
                      },
                      auth: {
                        username: jasper_username,
                        password: jasper_password
                      }
                    }).then((response) => {
                      resolve(response)
                      console.log(response)
                      const blob = new Blob([response.data], { type: 'application/pdf' })
                      const objectUrl = window.URL.createObjectURL(blob)
                      var link = document.createElement('a')
                      link.href = objectUrl
                      link.download = 'clergy.pdf'
                      link.click()
                  }).catch((error) => {
                      reject(error)
                  })
                })

            
            
              },
            },
          ]}
        /></ThemeProvider>   )}
    </div>
  );
};
export default ListSubmissionsPastor;
