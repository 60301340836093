
import React, {useRef, useState,useEffect,useMemo } from "react";


import 'react-toastify/dist/ReactToastify.css';
import YearPicker from "react-year-picker";
import ImageUploading from "react-images-uploading";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import axios from "axios";
import { useHistory } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "99%",

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const ChurchFormPageOneAdd = (props) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [show, setShow] = useState(false);
  const [awa, setAwa] = useState([]); 
  const [onlineAwa, setOnlineAwa] = useState([]); 
  const [worship, setWorship] = useState([]); 
  const [pastors, setPastors] = useState([]); 
  const [appointmentHistory, setAppointmentHistory] = useState([]); 
  const [appointment, setAppointment] = useState([]); 
  let awaObject={};
  let onlineAwaObject={};
  let worshipStyleObject={};
  useEffect(() => {

    const getChurchData = async () =>{
      try {
        let Church_ID=parseInt(localStorage.getItem("Church_ID"));
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/churchFormData/get/${Church_ID}`, { withCredentials: true }); 
        setPastors(res.data.clergy)
        setAppointment(res.data.appointment);
      } catch (err) {
       // console.log("ListSubmissionserr",err);
        toast(err);
      }
    }
    getChurchData();

   
  }, []);
  const columnsWorship = useMemo(
    () => [
   
      {
        field: 'Worship1',
        title: 'Worship Start Time',
        
        //size: 40,
      
      },
      {
        field: 'Worship2',
        title: 'Worship End Time',
        
        //size: 40,
      
      },
      {
        field: 'Worship3',
        title: 'Worship Style',
     
        //size: 20,
      }, 
 
     
    ],
      [],
    );
  const appointmentColumn = useMemo(
    () => [
   
      {
        field: 'Appointment1',
        title: 'From',
        
        //size: 40,
      
      },
      {
        field: 'Appointment2',
        title: 'To',
     
        //size: 20,
      }, 
      {
        field: 'Appointment3',
        title: 'Church',
     
        //size: 20,
      }, 
     
    ],
      [],
    );
  const columns = useMemo(
    () => [
   
      {
        field: 'year',
        title: 'Year',
 
        //size: 40,
     
      },
      {
        field: 'percentage',
        title: 'Attendance Percentage',
     
        //size: 20,
    
      },   ],
      [],
    );
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    var obj={}
    obj.Snapshot_current_Membership=imageList[0];
    obj.isImage=true;
    props.setImageData(obj);
  
  };

 
  useEffect( () => () => console.log("unmount"), [] );
  useEffect(() => {
   localStorage.removeItem("submissions")
  }, []);
  useEffect(() => {
    let obj={...props.inputs};
    obj.awa=awa;

    props.setInputs(obj);

   }, [awa]);
   useEffect(() => {
    let obj={...props.inputs};
    obj.worship=worship;

    props.setInputs(obj);

   }, [worship]);
   useEffect(() => {
    let obj={...props.inputs};
    obj.onlineAwa=onlineAwa;

    props.setInputs(obj);

   }, [onlineAwa]);

  const [err, setError] = useState(null);

  const handleChange = (e) => {
    if (e.target.name.includes("Pastor_ID")) {
      let id=parseInt( e.target.value);
      let filteredValue=pastors.filter(result=>result.ClergyId==id);   
      props.setInputs((prev) => ({ ...prev, Pastor_ID:  e.target.value}));
      console.log("Assigned_Pastor3")
      const obj=appointment.filter(a=>a.AppointmentId==e.target.value);
      setAppointmentHistory(obj);
          }
    if (e.target.name.includes("question")) {
      let combinedString = e.target.name + "%%" + e.target.value;
      props.setInputs((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else if(e.target.name.includes("input")){
      let combinedString = e.target.name + "%%" + e.target.value;
      props.setInputs((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else{
   
    console.log(e.target.name,e.target.value)
    props.setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
    console.log("props.inputs2",props.inputs)
  };
  const inputDate = (e) => {
    awaObject.year=e;


  }; 
  const handleWorshipStyle = (e) => {
    worshipStyleObject[e.target.name]=e.target.value;


  };
  const inputAttendance = (e) => {

    awaObject.percentage=e.target.value;
   
   
      };
      const inputDateOnline = (e) => {
        onlineAwaObject.year=e;
    
    
      };
      const inputAttendanceOnline = (e) => {
    
        onlineAwaObject.percentage=e.target.value;
       
       
          };
    
  const handleSubmit = async (e) => {
    console.log("handleSubmit55")
    e.preventDefault();
 
    history.push('/churchpagetwoadd');
  };
  const defaultMaterialTheme = createTheme();
  return (
               <div id = "frm"    >
     <div>
 
    </div>
            <h3 style={{display:"none"}}>Fill Church Section</h3> <br/>
          
    {/* <form   > */}
    <div className="row" style={{margin:"1px"}}>  
    <div className="col">
    <div className="form-element">
    <label>Church Name	: <span class="required"></span></label>
    <input required type="text" className="form-control" name="Church_Name" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Physical Address/Mailing Address: </label>
    <input type="text" className="form-control" name="Address" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Phone: </label>
    <input type="number" className="form-control" name="Phone" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Email: </label>
    <input type="email" className="form-control" name="Email" onChange={handleChange}/>
    </div><br/>
    <label>Assigned Pastor: </label>
    <select className="form-select" aria-label="Default select example"name="Pastor_ID" style={{height: "35px"}}  onChange={handleChange}>
     <option selected value="" disabled>Assigned Pastor</option>
     { pastors.map((result)=>(<option  value={result.Clergy1} >{result.Clergy1}</option>))}
  
  </select><br/>

    <div className="form-group">
    {( appointmentHistory.length>0 ) && (                  <ThemeProvider theme={defaultMaterialTheme}>
         <MaterialTable
          columns={appointmentColumn}
          data={appointmentHistory}
          title="Appointment History"
          options={{
            filtering: true,sorting: true
          }}
        /></ThemeProvider> )}
    <label for="exampleFormControlTextarea1">Mission Statement: </label>
    <textarea className="form-control" name="Mission_Statement" rows="3" onChange={handleChange}></textarea>
  </div><br/>
  <div className="form-group">
  <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg","png"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button  className="btn btn-success"
              style={isDragging ? { color: "red" } : null}
              onClick={onImageUpload}
              {...dragProps}
            >
              Upload Snapshot(less than 500kb)
            </button>
            &nbsp;
            <button  className="btn btn-success" onClick={onImageRemoveAll}>Remove all images</button>
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image.data_url} alt="" width="200" />
                <div className="image-item__btn-wrapper">
                  <button onClick={() => onImageUpdate(index)}>Update</button>
                  <button onClick={() => onImageRemove(index)}>Remove</button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
      <br/>
      <div className="row" style={{margin:"1px"}}> 
      <label>Average Worship Attendance(Online): </label> 
    <div className="col">
      <YearPicker onChange={inputDateOnline} /> 
      </div> 
  
      <div className="col">
      <input type="number" className="form-control" min="1" placeholder="Attendance Percentage"  max="100" onChange={inputAttendanceOnline}/>
      </div> 
      <div className="col">
      <button onClick={() => {
        var arr=[...onlineAwa];

        arr.push(onlineAwaObject);
                setOnlineAwa(arr);
               
                console.log("onlineAwaObject",props.inputs);
       
              }} className="btn btn-success">Add Yearly Attendance</button>      </div> 
      </div> <br/>
      <ThemeProvider theme={defaultMaterialTheme}>
         <MaterialTable
          columns={columns}
          data={onlineAwa}
          title="Yearly Online Attendance"
          options={{
            filtering: true,sorting: true
          }}
        /></ThemeProvider>
      <div className="row" style={{margin:"1px"}}> 
      <label>Average Worship Attendance(In-person): </label> 
    <div className="col">
      <YearPicker onChange={inputDate} /> 
      </div> 
      <div className="col">
      <input type="number" className="form-control" min="1" placeholder="Attendance Percentage"  max="100"name="Percentage" onChange={inputAttendance}/>
      </div> 
      <div className="col">
      <button onClick={() => {
        var arr=[...awa];

        arr.push(awaObject);
                setAwa(arr);
               
                console.log("awa",props.inputs);
       
              }} className="btn btn-success">Add Yearly Attendance</button>      </div> 
      </div> <br/>
  
                    <ThemeProvider theme={defaultMaterialTheme}>
         <MaterialTable
          columns={columns}
          data={awa}
          title="Yearly In-Person Attendance"
          options={{
            filtering: true,sorting: true
          }}
        /></ThemeProvider>
    </div> 

  
  </div> 
  <div className="col">
    <label>Setting : </label>
    <select className="form-select" aria-label="Default select example" name="Setting" onChange={handleChange}>
  <option value="Rural">Rural</option>
  <option value="Urban">Urban</option>
  <option value="Suburban">Suburban</option>
  <option value="Town">Town</option>
  <option value="Church Plant">Church Plant</option>
  </select><br/>
    <div className="form-element">
    <label>City Population: </label>
    <input type="number" className="form-control" name="City_Population" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>County Population: </label>
    <input type="number" className="form-control" name="County_Population" onChange={handleChange}/>
    </div><br/>
    <div className="form-group">
    <label for="exampleFormControlTextarea1">Worship: </label>
    <textarea className="form-control" name="question16" rows="1" onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Discipleship: </label>
    <textarea className="form-control" name="question17" rows="1" onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Fellowship & Congregational Care: </label>
    <textarea className="form-control" name="question18" rows="1" onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Missions & Outreach: </label>
    <textarea className="form-control" name="question19" rows="1" onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Number of Classes, Bands & Small Groups: </label>
    <textarea className="form-control" name="question20" rows="1" onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">How many new groups started in last 12 months: </label>
    <textarea className="form-control" name="question21" rows="1" onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Percentage of Congregation Engaged in Accountable Discipleship: </label>
    <textarea className="form-control" name="question22" rows="1" onChange={handleChange}></textarea>
  </div>
    <div className="form-group">
    <label for="exampleFormControlTextarea1">Description: </label>
    <textarea className="form-control" name="Description" rows="3" onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Church's Top 3 Strengths: </label>
    <textarea className="form-control" name="Church_Top_3_Strengths" rows="1" onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Opportunities for Ministry: </label>
    <textarea className="form-control" name="Opportunities_for_Ministry" rows="1" onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">What needs to Change: </label>
    <textarea className="form-control" name="What_needs_to_Change" rows="1" onChange={handleChange}></textarea>

  </div>
  <label>Worship Style : </label>
  <div className="row" style={{margin:"1px"}}>  
    <div className="col">
    <select className="form-select" aria-label="Default select example" name="Worship3" onChange={handleWorshipStyle}>
  <option value="contemporary">contemporary</option>
  <option value="blended">blended</option>
  <option value="traditional">traditional</option>
  <option value="liturgical">liturgical</option>
  </select> </div> 
  <div className="col">
    <input  type="time" placeholder="Worship Time" className="form-control" name="Worship1" onChange={handleWorshipStyle}/><br/>
    </div>  
    <div className="col">
    <input  type="time" placeholder="Worship Time" className="form-control" name="Worship2" onChange={handleWorshipStyle}/><br/>
    </div>  
    </div> 
    <button style={{width:"30%"}}onClick={() => {
        var arr=[...worship];

        arr.push(worshipStyleObject);
                setWorship(arr);
               
   
       
              }} className="btn btn-success">Add Worship Style</button>    
  <ThemeProvider theme={defaultMaterialTheme}>
         <MaterialTable
          columns={columnsWorship}
          data={worship}
          title="Worship Styles"
          options={{
            filtering: true,sorting: true
          }}
        /></ThemeProvider>
  <br/>
  </div> 
  </div>

  
{/* </form> */}




    </div>
  );
};
export default ChurchFormPageOneAdd;
