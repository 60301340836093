import React, {useRef, useState,useEffect,useMemo } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import YearPicker from "react-year-picker";
import ImageUploading from "react-images-uploading";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import axios from "axios";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  const PastorFormAdd = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    const [show, setShow] = useState(false);
    const [awa, setAwa] = useState([]); 
    const [churchData, setChurchData] = useState([]); 
    const [aws, setAws] = useState([]); 
    const [awb, setAwb] = useState([]);
    const [edu, setEdu] = useState([]);
    const [appointmentHistory, setAppointmentHistory] = useState([]);
    let awaObject={};
    let awsObject={};
    let awbObject ={};
    let eduobject = {Education3:"No"};
    const [eduObjLength, seteduObjLength] = useState(0);
    
    let appointmentobject = {};
    useEffect(() => {
      try {

        const getPastorData = async () =>{
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/churchFormData/getChurchDataForList/`, { withCredentials: true }); 
        setChurchData(res.data.church)
        }
              getPastorData();
      } catch (err) {
       // console.log("ListSubmissionserr",err);
        toast(err);
      }
     
    }, []);
   
    const appointmentColumn = useMemo(
      () => [
     
        {
          field: 'Appointment1',
          title: 'From',
          
          //size: 40,
        
        },
        {
          field: 'Appointment2',
          title: 'To',
       
          //size: 20,
        }, 
        {
          field: 'Appointment3',
          title: 'Church Name/id',
       
          //size: 20,
        }, 
       
      ],
        [],
      );
    const InEdu = useMemo(
      () => [
     

        {
          field: 'Education2',
          title: 'Degree name',
       
          //size: 20,
        }, 
        {
          field: 'Education1',
          title: 'Year Passed',
          
          //size: 40,
        
        },
        {
          field: 'Education3',
          title: 'Is Graduation Level',
       
          //size: 20,
        }, 
       
      ],
        [],
      );

    const Inperson = useMemo(
      () => [
     
        {
          field: 'Inperson1',
          title: 'Year',
          
          //size: 40,
        
        },
        {
          field: 'Inperson2',
          title: 'attendance',
       
          //size: 20,
        },   ],
        [],
      );
      const Onperson = useMemo(
        () => [
       
          {
            field: 'OnlineWorship1',
            title: 'Year',
            
            //size: 40,
          
          },
          {
            field: 'OnlineWorship2',
            title: 'attendance',
         
            //size: 20,
          },   ],
          [],
        );
  
    const columns = useMemo(
      () => [
     
        {
          field: 'Children1',
          title: 'Home',
          
          //size: 40,
        
        },
        {
          field: 'Children2',
          title: 'Ages',
       
          //size: 20,
        },   ],
        [],
      );
    const [images, setImages] = React.useState([]);
    const maxNumber = 69;
    const onChange = (imageList, addUpdateIndex) => {
      console.log(imageList, addUpdateIndex);
      setImages(imageList);
      var obj={}
      obj.Snapshot_current_Membership=imageList[0];
      obj.isImage=true;
      props.setImageData(obj);
    
    };

;
    useEffect(() => {
     localStorage.removeItem("submissions")
    }, []);
    useEffect(() => {
      let obj={...props.inputs};
      obj.awa=awa;
      console.log(typeof obj,obj)
      props.setInputs(obj);
  
     }, [awa]);
     useEffect(() => {
      let obj={...props.inputs};
      obj.aws=aws;

      props.setInputs(obj);
  
     }, [aws]);

     useEffect(() => {
      let obj={...props.inputs};
      obj.awb=awb;

      props.setInputs(obj);
  
     }, [awb]);

     useEffect(() => {
      let obj={...props.inputs};
      obj.edu=edu;
 
      props.setInputs(obj);
          for (const key in eduobject) {
          if (eduobject.hasOwnProperty(key)) {
            delete eduobject[key];
          }
        }
        eduobject.Education3="No";    
        const inputs = document.querySelectorAll('input[name*="Education"]');

// Iterate over the input fields and reset their values
inputs.forEach((input) => {
  if (input.type === 'checkbox') {
    input.checked = false;
  } else {
    input.value = '';
  }
});
     }, [edu]);
     useEffect(() => {
      let obj={...props.inputs};
      obj.appointmentHistory=appointmentHistory;
 
      props.setInputs(obj);
  
     }, [appointmentHistory]);
     
  
    const [err, setError] = useState(null);
    const handleAppointment = (e) => {
appointmentobject[e.target.name]=e.target.value;
  
    }


    
    const handleEducation= (e) => {
console.log("eduObjLength",eduObjLength,eduobject)
      if(e.target.name=="Education3"){
       if( e.target.checked==false){
        eduobject[e.target.name]="No"
       }
       else{
        eduobject[e.target.name]="Yes"
       }
      }

      else{
      eduobject[e.target.name]=e.target.value;
      }
      // seteduObjLength(Object.keys(eduobject).length)
        
          }
  
    const handleChange = (e) => {
      console.log(e.target.name,e.target.value)
      props.setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
     
      
      console.log("inputs2",props.inputs)
    };

    const inpersondate = (e) => {
      awsObject.Inperson1=e;
    };
    const Inpersondata = (e) => {
      awsObject.Inperson2 = e.target.value;
    }

    const onpersondate = (e) => {
      awbObject.OnlineWorship1=e;
    };

    const onpersondata = (e) => {
      awbObject.OnlineWorship2 = e.target.value;
    }
    const inputAttendance = (e) => {
  
      awaObject.Children1=e.target.value;
      
     
        };
        const inputAttendance2 = (e) => {
  
          awaObject.Children2=e.target.value;
     
     
         
            };


    const defaultMaterialTheme = createTheme();
    return (
                 <div  >

      <div className="row" style={{margin:"1px"}}>  
      <div className="col">
      <div className="form-element">
      <label>Clergy First Name	:<span class="required"></span> </label>
      <input required type="text" className="form-control" name="Clergy1" onChange={handleChange}/>
      </div><br/>
      <div className="form-element">
      <label>Clergy Last Name	:<span class="required"></span> </label>
      <input required type="text" className="form-control" name="Clergy111" onChange={handleChange}/>
      </div><br/> 
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg","png"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button  className="btn btn-success"
              style={isDragging ? { color: "red" } : null}
              onClick={onImageUpload}
              {...dragProps}
            >
              Upload Snapshot(less than 500kb)
            </button>
            &nbsp;
            <button  className="btn btn-success" onClick={onImageRemoveAll}>Remove all images</button>
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image.data_url} alt="" width="200" />
                <div className="image-item__btn-wrapper">
                  <button onClick={() => onImageUpdate(index)}>Update</button>
                  <button onClick={() => onImageRemove(index)}>Remove</button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
      <div className="form-element">
      <label>Email:<span class="required"></span> </label>
      <input type="email" className="form-control" name="Clergy2" onChange={handleChange}/>
      </div><br/>
      <div className="form-element">
      <label>Cell:<span class="required"></span> </label>
      <input type="number" className="form-control" name="Clergy3" onChange={handleChange}/>
      </div><br/>
   
     <div className="form-element">
      <label>Social Media Link:<span class="required"></span> </label>
      <input type="text" className="form-control" name="Clergy4" onChange={handleChange}/>
      </div><br/>
      <div className="form-group">

      <label for="exampleFormControlTextarea1">LinkedIn profile:<span class="required"></span> </label>
      <textarea className="form-control" id="Mission_Statement"  name="Clergy5" rows="2" onChange={handleChange}></textarea>
    </div><br/>
   
 
        <br/>
        <div className="row" style={{margin:"1px"}}> 
      <label>Spouse Name:<span class="required"></span> </label> 
      <div className="form-group">
      <input type="text" className="form-control"  min="1" placeholder=""  max="100" name="Clergy16" onChange={handleChange}/>
      </div>
      <label>Children living in the home and their ages:<span class="required"></span> </label> <br/>
      <div className="col">
      <input type="text" className="form-control"  min="1" placeholder="Name"  max="100" name="Children1" onChange={inputAttendance}/>
      </div> 
      <div className="col">
      <input type="number" className="form-control" min="1" placeholder="Age"  max="100"name="Children2" onChange={inputAttendance2}/>
      </div> 
      <div className="col">
      <button onClick={() => {
        var arr=[...awa];

        arr.push(awaObject);
                setAwa(arr);
               
                console.log("awa",props.inputs);
       
              }} className="btn btn-success">Add Child</button>     
               </div> 
      </div> <br/>
 
                      <ThemeProvider theme={defaultMaterialTheme}>
           <MaterialTable
            columns={columns}
            data={awa}
            title="Children"
            options={{
              filtering: true,sorting: true
            }}
          /></ThemeProvider>
  
      <div className="row" style={{margin:"1px"}}> 
      <label>Average In-Person Worship Attendance:<span class="required"></span> </label> 
    <div className="col">
      <YearPicker name="Inperson1" onChange={inpersondate} /> 
      </div> 
      <div className="col">
      <input type="number" className="form-control" min="1" placeholder="Average In-Person Worship Attendance"  max="100"name="Inperson2" onChange={Inpersondata}/>
      </div> 
      <div className="col">
      <button onClick={() => {
        var arr=[...aws];
console.log("awsObject",awsObject)
        arr.push(awsObject);
                setAws(arr);
               
             
       
              }} className="btn btn-success">Add  Attendance</button>      </div> 
      </div> <br/>
      <ThemeProvider theme={defaultMaterialTheme}>
           <MaterialTable
            columns={Inperson}
            data={aws}
            title="Average In-Person  Attendance"
            options={{
              filtering: true,sorting: true
            }}
          /></ThemeProvider>
<br/>
<div className="row" style={{margin:"1px"}}> 
      <label><b>Educational Information:</b><span class="required"></span> </label> 
     
    <div className="col">
       <label>Year Passed<span class="required"></span> </label>
      <input type="date" className="form-control"  name="Education1" onChange={handleEducation} placeholder="Graduation Year" /> 
      </div>  
      <div className="col">
      <label>Degree Name<span class="required"></span> </label>
      <input type="text" className="form-control" min="1" placeholder="Degree Name"  max="100"name="Education2" onChange={handleEducation}/>
      </div>  
  
      <div className="row" style={{margin:"1px"}}>
      <div className="col" >
      <br/>
      <input type="checkbox" id="option1" name="Education3"  onChange={handleEducation} />
      <label htmlFor="option1">  Is Graduation Level<span class="required"></span> </label>
      </div>
      
     
      <div className="col">
      <br/>
     
      
      <button onClick={() => {
        var arr=[...edu];
console.log("eduobject22",eduobject,arr)
        arr.push(eduobject);
 
        setEdu(arr);

             
       
              }} className="btn btn-success">Add Education</button>  
           {/* {( eduObjLength>1) && (   )}  */ }
                 </div> 
                 </div>
                 <br/>     <br/>
          <ThemeProvider theme={defaultMaterialTheme}>
            <br/>
           <MaterialTable
            columns={InEdu}
            data={edu}
            title="Education Info"
            options={{
              filtering: true,sorting: true
            }}
          /></ThemeProvider>
           </div>

    
    </div> 
    <div className="col">
      <label>Martial Status :<span class="required"></span> </label>
      <select id="martialStatus" className="form-select" aria-label="Default select example" name="Clergy6" onChange={handleChange}>
    <option value="Married">Married</option>
    <option value="UnMarried">UnMarried</option>
   
    </select><br/>
      <div className="form-element">
      <label>Specific Family Needs:<span class="required"></span> </label>
      <input type="text" className="form-control" name="Clergy7" onChange={handleChange}/>
      </div><br/>
      
     
      <div className="form-element">
      <label>Years in Current Appointment:<span class="required"></span> </label>
      <input type="text" className="form-control" name="Clergy8" onChange={handleChange}/>
      </div><br/>
      <div className="form-element">
      <label>Total Years in Ordained or Licensed Ministry:<span class="required"></span> </label>
      <input type="text" className="form-control"  name="Clergy9" onChange={handleChange}/>
      
      </div><br/>
      <div className="form-element">
      <label>Current Pastoral Compensation:<span class="required"></span> </label>
      <input type="text"  className="form-control" placeholder="Cash salary" name="Clergy10" onChange={handleChange}/>
      <input type="text"  className="form-control" placeholder="ARP" name="Clergy11" onChange={handleChange}/>
      <input type="text"  className="form-control" placeholder="Housing Allowance" name="Clergy12" onChange={handleChange}/>
      </div><br/>
    <div className="form-group">
      <label for="exampleFormControlTextarea1">Appointment History:<span class="required"></span> </label>
      <div className="row" style={{margin:"1px"}}> 
    <div className="col">
    <input type="date" name="Appointment1" className="form-control" placeholder="from"  onChange={handleAppointment}/>
    </div>
    <div className="col">
    <input type="date" name="Appointment2" className="form-control" placeholder="to"   onChange={handleAppointment}/>
    </div>  
    </div>
    <div className="row" style={{margin:"1px"}}> 
    <div className="col">
    <div className="form-group" style={{height:"130%"}}> 
    <select className="form-select" aria-label="Default select example" style={{}} name="Appointment3"  onChange={handleAppointment}>
     <option selected value="" disabled>Select From Existing Church</option>
     { churchData.map((result)=>(<option  value={result.Church_Name} >{"id:"+result.Church_ID+" name:"+result.Church_Name}</option>))}
  </select>
    </div>
    </div>
    <div className="col">
    <div className="form-group" style={{height:"130%"}}> 
    <input type="text" name="Appointment3" className="form-control" placeholder="Or Add New Church Name"   onChange={handleAppointment}/>

    </div>
    </div>

 

 </div>
 <button style={{width:"30%"}} onClick={() => {
        var arr=[...appointmentHistory];

        arr.push(appointmentobject);
                setAppointmentHistory(arr);
               
            
       
              }} className="btn btn-success">Add Appointment History</button>    
 <ThemeProvider theme={defaultMaterialTheme}>
           <MaterialTable
            columns={appointmentColumn}
            data={appointmentHistory}
            title="Appointment history"
            options={{
              filtering: true,sorting: true
            }}
          /></ThemeProvider>


    </div>
    <div className="form-group">
      <label for="exampleFormControlTextarea1">Special Trainings:<span class="required"></span> </label>
      <textarea className="form-control" name="Clergy15" rows="1" onChange={handleChange}></textarea>
    </div>
    <div className="form-group">
      <label for="exampleFormControlTextarea1">Gifts for Ministry:<span class="required"></span> </label>
      <textarea className="form-control" name="Clergy13" rows="1" onChange={handleChange}></textarea>
    </div>
    <div className="form-group">
      <label for="exampleFormControlTextarea1">Growing edge:<span class="required"></span> </label>
      <textarea className="form-control" name="Clergy14" rows="1" onChange={handleChange}></textarea>
    </div>
  
    <br/>
    <div className="row" style={{margin:"1px"}}> 
      <label>Average OnLine Attendance:<span class="required"></span> </label> 
    <div className="col">
      <YearPicker name="OnlineWorship1" onChange={onpersondate} /> 
      </div> 
      <div className="col">
      <input type="number" className="form-control" min="1" placeholder="Average OnLine Attendance"  max="100"name="OnlineWorship2" onChange={onpersondata}/>
      </div> 
      <div className="col">
      <button onClick={() => {
        var arr=[...awb];
console.log("awbObject",awbObject)
        arr.push(awbObject);
                setAwb(arr);
               
             
       
              }} className="btn btn-success">Add  Attendance</button>      </div> 
      </div> <br/>
      <ThemeProvider theme={defaultMaterialTheme}>
           <MaterialTable
            columns={Onperson}
            data={awb}
            title="Average OnLine  Attendance"
            options={{
              filtering: true,sorting: true
            }}
          /></ThemeProvider>
    
    </div> 
<hr></hr>


    </div>
  <hr></hr>
   
  {/* </form> */}
  
  <ToastContainer />
  <br/>

      </div>
    );
  };

  export default PastorFormAdd;