import axios from "axios";
import React, {useRef, useState,useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import NotificationReact from "pages/NotificationReact";
import { useContext } from "react";
import { Link} from "react-router-dom";
import { AuthContext } from "../context/authContext";
import bg from "../assets/img/background_image2.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import styles from"../layouts/style.module.css";
// import "./style.css"
const Login = () => {
  const history = useHistory();
  const buttonRef = useRef(null); 
 function refreshText(){
    setText("");
    }
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");
  const [text, setText] = useState(""); 
  useEffect(() => {
    if(text!==""){
    buttonRef.current.click();
    }

  }, [text]);
  const [ip, setIp] = useState(null);
  // document.cookie = `referral_key=hello;max-age=604800;domain=example.com`;

  // document.cookie = "username=John Doe; expires=Thu, 18 Dec 2013 12:00:00 UTC";
  const cookies = new Cookies();

  $.get('https://www.cloudflare.com/cdn-cgi/trace', function(data) {
    // Convert key-value pairs to JSON
    // https://stackoverflow.com/a/39284735/452587
    data = data.trim().split('\n').reduce(function(obj, pair) {
      pair = pair.split('=');
      return obj[pair[0]] = pair[1], obj;
    }, {});
   
    setIp(data.ip);
  });
  useEffect(() => {
 
    localStorage.removeItem("user");
    console.log(ip);
    if(ip!==null){
    setInputs((prev) => ({ ...prev, ip_address: ip }));
    }
  }, [ip]);
 
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
    login_time:new Date(),
    ip_address:ip,
  });
 
  const [err, setError] = useState(null);




  const inlineStyle = {
    backgroundImage: "url('/material-dashboard-react/static/media/background_image2.bdcadddd.png')",
    backgroundSize: '180% auto',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    opacity:'0.4'
  };

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const login = async (inputs) => {
    const res = await axios.post(`${process.env.REACT_APP_URL}/api/auth/login`, inputs, { withCredentials: true });
    console.log("res44",res)
    localStorage.setItem("user", JSON.stringify(res.data));

  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(inputs)
      localStorage.setItem("navFlag", true);
      localStorage.setItem("currentUrl","/admin/ListSubmissions")
      history.push('/admin/ListSubmissions');
    } catch (err) {
      setError(err.response.data);
            await refreshText();
           setText(() =>"");
//toast(err.response.data);
   
      setColor("red");
           setTitle("Login Error");
      setText(err.response.data);
    }
   
    setError(null);
  };
    return (
    <>
  
   
      
    <div className='loginChurch'   >
    {/* <img src={bg} height="auto" width="80%" alt="Logo" clasName="bg-img" /> */}




      {/* <img src={bg} height="auto" width="100%" alt="Logo" clasName="bg-img" />  style={{ backgroundImage: `url(${bg})` }}*/}
      <div >
          <div id = "frm"  >
            <h3 style={{}}>Welcome to Church Management Application</h3> <br/>
        
    <form method="post" action="" onSubmit={handleSubmit} >
    <div className="form-element">
    <label>Username:<span class="required"></span> </label>
    <input required type="text" className="form-control" name="username" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Password:<span class="required"></span> </label>
    <input required type="password" className="form-control" name="password" onChange={handleChange}/>
    </div><br/>
    <input type="submit" className="btn btn-success"  value="Login"/>

<br/>    
<span>
Don't you have an account? <Link to="/register">Register</Link>
</span> 


        
</form>
</div> 
</div>


</div>
<ToastContainer />

<NotificationReact
      buttonRef={buttonRef}
      title={title}
      color={color}
      text={text}
      />
    </>
  );
};

export default Login;

