
import React, {useRef, useState,useEffect,useMemo } from "react";

import { useHistory } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const ChurchFormPageThreeAdd = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [awa, setAwa] = useState([]); 
  let awaObject={};
 
  const columns = useMemo(
    () => [
   
      {
        field: 'year',
        title: 'Year',
 
        //size: 40,
     
      },
      {
        field: 'percentage',
        title: 'Attendance Percentage',
     
        //size: 20,
    
      },   ],
      [],
    );
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };


 let initialValue={};
 let Church_ID;  

  useEffect(() => {
   localStorage.removeItem("submissions")

  
  }, []);

  useEffect(() => {
    function findPropertiesWithPercent(obj) {
      const valuesAfterPercent = [];
    
      Object.keys(obj).forEach((key)       => {
        const propertyValue = obj[key];
        if (typeof propertyValue === "string" && propertyValue.includes("%%") && key.includes("input")) {
          const valueAfterPercent = propertyValue.split("%%")[1];
          valuesAfterPercent.push(parseInt(valueAfterPercent));
        }
      });
    
      return valuesAfterPercent;
    }
    
    // Usage example
    const arr = findPropertiesWithPercent(props.inputs);
    props.setvaluesAfterPercent(arr);
 
   
   }, [props.inputs]);

  useEffect(() => {
    let obj={...props.inputs};
    obj.awa=awa;
    console.log(typeof obj,obj)
    props.setInputs(obj);

   }, [awa]);

  const [err, setError] = useState(null);

  const handleChange = (e) => {
    if (e.target.name.includes("question")) {
      let combinedString = e.target.name + "%%" + e.target.value;
      props.setInputs((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else if(e.target.name.includes("input")){
      let combinedString = e.target.name + "%%" + e.target.value;
      props.setInputs((prev) => ({ ...prev, [e.target.name]: combinedString}));
  
    
    }
    else{
   
    console.log(e.target.name,e.target.value)
    props.setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
    console.log("props.inputs2",props.inputs)
  };
  const inputDate = (e) => {
    awaObject.year=e;
  };
  const inputAttendance = (e) => {
    awaObject.percentage=e.target.value;
      };
    
  const handleSubmit = async (e) => {
    console.log("handleSubmit55")
    e.preventDefault();
    window.location.replace('/churchpagetwo');
  };

  return (
               <div     >
                <br/>  


   

        <div className="row" style={{margin:"1px"}}>  
    <div className="col">
        <table className="table" >
  <thead>
    <tr>
      {/* <th scope="col">#</th> */}
      <th scope="col">Rank</th>
      <th scope="col" style={{width:"25%"}}>Leadership Skill</th>
      <th scope="col">Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      <td  style={{
    width: "15%"
}}> <input name="input1" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input1||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Leadership & Vision-Casting</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Serving as the leader of the leaders in the congregation, the person who articulates the vision and focuses the work of the whole congregation on that vision.
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">2</th>
      <td  style={{
    width: "15%"
}}> <input name="input2" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input2||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Planning & Leading Worship</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Planning and conducting worship services, Preparing computer information for services, and working with others who lead and participate in worship.
</td>
    </tr>
   
    <tr>
      <th style={{display:"none"}} scope="row">3</th>
      <td  style={{
    width: "15%"
}}> <input name="input3" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input3||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Community Leader</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Serving as a volunteer leader in the community to help make the community stronger and our world a better place (Kiwanis, Rotary, Optimus, town/city/county boards, etc.)
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">4</th>
      <td  style={{
    width: "15%"
}}> <input name="input4" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input4||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Staff Supervision</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Overseeing the staff of the church, setting priorities, and di- recting the ministry leaders of the church.
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="input5" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input5||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Discipleship</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Helping others to identify their own special call to service, and enabling them to respond, by developing and fol- lowing a discipline of Bible and other devotional study, partici- pating in programs of continuing education and service to the Kingdom through their giftedness.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">5</th>
      <td  style={{
    width: "15%"
}}> <input name="input16" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input16||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Teaching</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Planning and teaching short-term classes, teachingbible studies, teaching confirmation classes, teaching training classes on leadership, evangelism, etc.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">6</th>
      <td  style={{
    width: "15%"
}}> <input name="input6" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input6||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Prophetic Ministry</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Speaking the truth of scripture into the culture and community and seeking to lead the community in making a Kingdom difference in the world.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">6</th>
      <td  style={{
    width: "15%"
}}> <input name="input17" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Personal & Spiritual Growth</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Developing and following a discipline of Bible Study and other devotional study, participating in programs of personal development and education, in order to lead more effectively.  </td>
  </tr>
    <tr>
      <th style={{display:"none"}} scope="row">7</th>
      <td  style={{
    width: "15%"
}}> <input name="input7" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input7||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Office Management</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Keeping accurate records, collecting data, and filling out needed reports</td>
    </tr>
 
   
  </tbody>
</table>

 
</div>
<div className="col">
        <table className="table" >
  <thead>
    <tr>
      {/* <th scope="col">#</th> */}
      <th scope="col">Rank</th>
      <th scope="col">Leadership Skill</th>
      <th scope="col">Description</th>
    </tr>
  </thead>
  <tbody>
  <tr>
      <th style={{display:"none"}} scope="row">8</th>
      <td  style={{
    width: "15%"
}}> <input name="input8" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input8||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Administration</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Serving as the executive of the congregation, working with the committees on planning and implementation of programs</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">9</th>
      <td  style={{
    width: "15%"
}}> <input name="input9" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input9||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Communications</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Handling the correspondence of the church, channeling com- munications, getting information into bulletins, newsletters, onto the internet, etc.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">10</th>
      <td  style={{
    width: "15%"
}}> <input name="input10" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input10||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Evangelism</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Calling on the unchurched, nominally churched, de-churched, and people in the community, calling on prospective new members, and generally bearing witness to the Good News in hopes that individuals will accept the Gospel Message.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">11</th>
      <td  style={{
    width: "15%"
}}> <input name="input11" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input11||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Counseling & Spiritual Direction</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Meeting with individuals on a one-on-one basis to work through personal and spiritual problems, helping them to put together a spiritual plan, meeting with couples planning to be married, or those who are dealing with the issues of life.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">12</th>
      <td  style={{
    width: "15%"
}}> <input name="input12" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input12||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Preaching</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Serving as the leader of the leaders in the congregation, the person who articulates the vision and focuses the work of the whole congregation on that vision.
</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">13</th>
      <td  style={{
    width: "15%"
}}> <input name="input13" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input13||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Visitation</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Calling on those associated with the church family in their homes or at their places of work in a systematic program to meet each member where they are most comfortable.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">14</th>
      <td  style={{
    width: "15%"
}}> <input name="input14" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input14||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Financial Planning & Fundraising</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Helping to plan the financial program of the church, creating and executing a plan to provide funding for the church and/or any special project or general ministry need.</td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row">15</th>
      <td  style={{
    width: "15%"
}}> <input name="input15" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}  id={props.inputs.input15||""}  onChange={handleChange}/></td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Healer</td>
      <td style={{
fontStyle: "italic",fontSize:"small"}}>
Builds bridges and restores relationships by creating a healthy environment, with models healthy work/life balance with spiritual and emotional maturity. </td>
    </tr>

  </tbody>
</table>

</div>
<div className="form-group">
    <label for="exampleFormControlTextarea1">2.	What personality traits of a clergy leader best match the culture of this local church?
<span class="required"></span> </label>
    <textarea required className="form-control"  name="question14"  rows="1" id={props.inputs.question14||""}  onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">1.	What other critical pastoral gifts and skills are needed to help you move toward that vision? <span class="required"></span> </label>
    <textarea required  className="form-control"  name="question15"  rows="1" id={props.inputs.question15||""}  onChange={handleChange}></textarea>
  </div>
</div>

  {/* <div style={{textAlign:"center"}} >

       
<button style={{width:"10%"}} onClick={() => {
   handleClose();   
            }}   
            className="btn btn-success" >Save</button>
            <button  style={{width:"10%"}} onClick={handleClose}   
            className="btn" >Close</button>
</div>  
       */}

    
    </div>
  );
};
export default ChurchFormPageThreeAdd;



