/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
   
        <p className={classes.right}>
          <span>
            &copy; {1900 + new Date().getYear()}{" "}
            <a
              href="https://pascalinesoft.com/"
              target="_blank"
              className={classes.a}
            >
              Copyright Pascaline Soft (Private) Limited. All rights reserved.
            </a>
         
          </span>
        </p>
      </div>
    </footer>
  );
}

{/* <div className={classes.left}>
<List className={classes.list}>
  <ListItem className={classes.inlineBlock}>
    <a href="https://pascalinesoft.com" className={classes.block}>
      Home
    </a>
  </ListItem>
  <ListItem className={classes.inlineBlock}>
    <a href="https://support.pascalinesoft.com" className={classes.block}>
      Support
    </a>
  </ListItem>
  <ListItem className={classes.inlineBlock}>
    <a href="http://dc.ps.com/" className={classes.block}>
      Attendance
    </a>
  </ListItem>
  {/* <ListItem className={classes.inlineBlock}>
    <a href="#blog" className={classes.block}>
      Blog
    </a>
  </ListItem> */}
// </List>
// </div>