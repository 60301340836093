/*!

=========================================================
* Church Management Application - v1.10.0
=========================================================

* Product Page: https://www.pascalinesoft.com/product/material-dashboard-react
* Copyright 2021 Saad Hassan Shah (https://www.pascalinesoft.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by saad hassan

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
/* eslint-disable */
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";

// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";

import ChurchFormPageOne from "pages/ChurchFormPageOne";
import Register from "pages/Register";

import ListSubmissions from "pages/ListSubmissions";
import ChurchFormPageOneAdd from "pages/ChurchFormPageOneAdd";
import { ChurchFormAddTabs } from "pages/ChurchFormAddTabs";
import PastorFormAdd from "pages/PastorFormAdd";
import ListSubmissionsPastor from "pages/ListSubmissionsPastor";
import { PastorFormAddTabs } from "pages/PastorFormAddTabs";
import RankingComparison from "pages/RankingComparison";
const user = JSON.parse(localStorage.getItem("user"));

 
console.log("jwt3",user)
let dashboardRoutes;
// if( user==null ){
//  dashboardRoutes = [
//   {
//     path: "/dashboard",
//     name: "Dashboard",
//     rtlName: "لوحة القيادة",
//     icon: Dashboard,
//     component: DashboardPage,
//     layout: "/admin",
//   },
 
//   {
//     path: "/homepage",
//     name: "HomePage",
//     rtlName: "ملف تعريفي للمستخدم",
//     icon: Person,
//     component: HomePage,
//     layout: "/admin",
//   },
//   {
//     path: "/ChurchFormPageOne",
//     name: "Add Project",
//     rtlName: "ملف تعريفي للمستخدم",
//     icon: Person,
//     component: ChurchFormPageOne,
//     layout: "/admin",
//   },
//   {
//     path: "/assignproject",
//     name: "Assign Project",
//     rtlName: "ملف تعريفي للمستخدم",
//     icon: Person,
//     component: AssignProject,
//     layout: "/admin",
//   },
//   {
//     path: "/adduser",
//     name: "Add User",
//     rtlName: "ملف تعريفي للمستخدم",
//     icon: Person,
//     component: Register,
//     layout: "/admin",
//   },
//   {
//     path: "/users",
//     name: "View/Modify Users",
//     rtlName: "ملف تعريفي للمستخدم",
//     icon: Person,
//     component: Users,
//     layout: "/admin",
//   },
//   // {
//   //   path: "/table",
//   //   name: "Table List",
//   //   rtlName: "قائمة الجدول",
//   //   icon: "content_paste",
//   //   component: TableList,
//   //   layout: "/admin",
//   // },
//   // {
//   //   path: "/typography",
//   //   name: "Typography2",
//   //   rtlName: "طباعة",
//   //   icon: LibraryBooks,
//   //   component: Typography,
//   //   layout: "/admin",
//   // },
//   // {

//   // {
//   //   path: "/upgrade-to-pro",
//   //   name: "Upgrade To PRO",
//   //   rtlName: "التطور للاحترافية",
//   //   icon: Unarchive,
//   //   component: UpgradeToPro,
//   //   layout: "/admin",
//   // },
// ];
// }
// else{
   dashboardRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      rtlName: "لوحة القيادة",
      icon: Dashboard,
      component: DashboardPage,
      layout: "/admin",
    },
    {
      path: "/ChurchFormPageOneadd",
      name: "Submit Form Church",
      rtlName: "ملف تعريفي للمستخدم",
      icon: Person,
      component: ChurchFormAddTabs,
      layout: "/admin",
    },
    {
      path: "/ListSubmissions",
      name: "Church Submissions",
      rtlName: "ملف تعريفي للمستخدم",
      icon: Person,
      component: ListSubmissions,
      layout: "/admin",
    },

    {
      path: "/pastorformadd",
      name: "Submit Form Clergy",
      rtlName: "ملف تعريفي للمستخدم",
      icon: Person,
      component: PastorFormAddTabs,
      layout: "/admin",
    },
    {
      path: "/ListSubmissionspastor",
      name: "Clergy Submissions",
      rtlName: "ملف تعريفي للمستخدم",
      icon: Person,
      component: ListSubmissionsPastor,
      layout: "/admin",
    },
    {
      path: "/rankingcomparison",
      name: "Ranking Comparison",
      rtlName: "ملف تعريفي للمستخدم",
      icon: Person,
      component: RankingComparison,
      layout: "/admin",
    },
  ]
  // }
export default dashboardRoutes;
