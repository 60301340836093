
import React, {useRef, useState,useEffect,useMemo } from "react";
import axios from "axios";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import YearPicker from "react-year-picker";
import ImageUploading from "react-images-uploading";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const ChurchFormPageOne = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [awa, setAwa] = useState([]); 
  const [pastors, setPastors] = useState([]); 
  const [appointmentHistory, setAppointmentHistory] = useState([]); 
  const [worship, setWorship] = useState([]); 
  let worshipStyleObject={};
  const [appointment, setAppointment] = useState([]); 
  const [img, setImg] = useState(null); 
  let awaObject={};
 console.log("img",img)
 const appointmentColumn = useMemo(
  () => [
 
    {
      field: 'Appointment1',
      title: 'From',
      
      //size: 40,
    
    },
    {
      field: 'Appointment2',
      title: 'To',
   
      //size: 20,
    }, 
    {
      field: 'Appointment3',
      title: 'Church',
   
      //size: 20,
    }, 
   
  ],
    [],
  );
  const columns = useMemo(
    () => [
   
      {
        field: 'Year',
        title: 'Year',
 
        //size: 40,
     
      },
      {
        field: 'Attendance',
        title: 'Attendance Percentage',
     
        //size: 20,
    
      },   ],
      [],
    );
    const columnsWorship = useMemo(
      () => [
     
        {
          field: 'Worship1',
          title: 'Worship Start Time',
          
          //size: 40,
        
        },
        {
          field: 'Worship2',
          title: 'Worship End Time',
          
          //size: 40,
        
        },
        {
          field: 'Worship3',
          title: 'Worship Style',
       
          //size: 20,
        }, 
   
       
      ],
        [],
      );
  const [images, setImages] = React.useState([]);
  const maxNumber =1;

  const onChange = (imageList, addUpdateIndex) => {
    console.log("imageList",imageList)
    // data for submit
   // console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  let Church_ID=parseInt(localStorage.getItem("Church_ID"));
  const [inputs, setInputs] = useState({church_ID:parseInt(Church_ID)});
  const [inputsEdit, setInputsEdit] = useState({church_ID:parseInt(Church_ID)});
 let initialValue={};

 const churchFormApiCall3 = async () => {
  try {
   
    const res = await axios.post(`${process.env.REACT_APP_URL}/api/churchFormData/update`, inputsEdit , { withCredentials: true });  
    toast('Form Updated Successfully');
    localStorage.removeItem("submissionsEdit");
    setTimeout(function () {

      history.push('/admin/ListSubmissions');
    }, 2000);

  } catch (err) {
   // console.log("churchFormData",err);
    toast(err);
  }
}

  useEffect(() => {
   localStorage.removeItem("submissions")

  }, []);
  useEffect( () => () => console.log("unmount"), [] );

   

  let url;

  useEffect(() => {
    Church_ID=parseInt(localStorage.getItem("Church_ID"));
    console.log("Church_ID" ,Church_ID)
    const getChurchData = async () =>{
      https://127.0.0.1:8000/api/churchFormData/103
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/churchFormData/get/${Church_ID}`, { withCredentials: true }); 
        setPastors(res.data.clergy)
        setAppointment(res.data.appointment);
        initialValue.church=(res.data.church.filter(a=>a.Church_ID==Church_ID))[0];
        console.log("initialValue.worship",res.data.worship);
        setImg(initialValue.church.Snapshot_current_Membership);
        delete initialValue.church.Snapshot_current_Membership;

setWorship(res.data.worship);
        initialValue.church_awa_in_person=res.data.church_awa_in_person.filter(a=>a.Church_ID==Church_ID);
        setAwa(initialValue.church_awa_in_person);
        initialValue.church_leadership_ranking=res.data.church_leadership_ranking.filter(a=>a.Church_ID==Church_ID);
        initialValue.financialform=res.data.financialform.filter(a=>a.Church_ID==Church_ID);
        initialValue.churchQuestion=res.data.church_question.filter(a=>a.Church_ID==Church_ID);
      
        function getAllProperties(obj) {
          const result = {};
        
          function traverse(obj, path) {
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                const newPath = path ? `${path}_${key}` : key;
        
                if (typeof value === 'object' && value !== null) {
                  traverse(value, newPath);
                } else {
                  result[newPath] = value;
                }
              }
            }
          }
        
          traverse(obj, '');
        
          return result;
        }
        
        // Example usage:
        
        
        const properties = getAllProperties(initialValue);
       // console.log("initialValue",initialValue)
     
        for (let prop in properties) {
   
          if (typeof properties[prop]=="string" && properties[prop].includes("%%")) {
            let substrings = properties[prop].split("%%");
            properties[substrings[0]] = substrings[1];
          }
        }
       // console.log("properties342",properties);
        setInputs(properties);
      } catch (err) {
       // console.log("ListSubmissionserr",err);
        toast(err);
      }
    }
    getChurchData();

   
  }, []);
  useEffect(() => {
    let obj={...inputsEdit};
    obj.awa=awa;
   // console.log(typeof obj,obj)
   setInputsEdit(obj);

   }, [awa]);
   useEffect(() => {
    let obj={...inputsEdit};
    obj.worship=worship;

    setInputsEdit(obj);

   }, [worship]);
  const [err, setError] = useState(null);

  const handleChange = (e) => {
    
    if (e.target.name.includes("Pastor_ID")) {
      let id=parseInt( e.target.value);
      let filteredValue=pastors.filter(result=>result.ClergyId==id);   
      setInputsEdit((prev) => ({ ...prev, Pastor_ID: e.target.value}));
const obj=appointment.filter(a=>a.AppointmentId==e.target.value);
setAppointmentHistory(obj);
    }
    if (e.target.name.includes("question")) {
      let combinedString = e.target.name + "%%" + e.target.value;
      setInputsEdit((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else if(e.target.name.includes("input")){
      let combinedString = e.target.name + "%%" + e.target.value;
      setInputsEdit((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else{
   
   console.log("setInputsEdit")
    setInputsEdit((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
   console.log("inputsEdit",inputsEdit)
  };
  const inputDate = (e) => {
    awaObject.Year=e;
  };
  const handleWorshipStyle = (e) => {
    worshipStyleObject[e.target.name]=e.target.value;


  };
  const inputAttendance = (e) => {
    awaObject.Attendance=e.target.value;
      };
      const bufferToBase64 = (buffer) => {
        const base64String = Buffer.from(buffer).toString('base64');
        return `data:image/jpeg;base64,${base64String}`;
      };
  const handleSubmit = async (e) => {
   // console.log("handleSubmit55")
    e.preventDefault();
    window.location.replace('/churchpagetwo');
  };
  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };
  const [fillActive, setFillActive] = useState('tab1');
  const defaultMaterialTheme = createTheme();
  return (
               <  >
        





 


  

     <div>

    </div>
            <h3 style={{display:"none"}}>Fill Church Section</h3> <br/>
          
    {/* <form   > */}
    <div className="row" style={{margin:"1px"}}>  
  

  
    <div className="col">
    <div className="form-element">
    <label>Church Name22	:<span class="required"></span> </label>
    <input required type="text" className="form-control" name="Church_Name" placeholder={inputs.church_Church_Name}   onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Physical Address/Mailing Address:<span class="required"></span> </label>
    <input type="text" className="form-control" placeholder={inputs.church_Address}  name="Address"   onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Phone:<span class="required"></span> </label>
    <input type="number" className="form-control" name="Phone" placeholder={inputs.church_Phone||""}  onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Email:<span class="required"></span> </label>
    <input type="email" className="form-control" name="Email" placeholder={inputs.church_Email||""}  onChange={handleChange}/>
    </div><br/>
    <label>Assigned Pastor:<span class="required"></span> </label>
     <select className="form-select" aria-label="Default select example" style={{height: "35px"}}  placeholder={inputs.church_Pastor_ID||""}  name="Pastor_ID"  onChange={handleChange}>
     <option selected value="" disabled>Assigned Pastor</option>
     { pastors.map((result)=>(<option  value={result.Clergy1} >{result.Clergy1}</option>))}
  </select><br/>
  {( appointmentHistory.length>0 ) && (                  <ThemeProvider theme={defaultMaterialTheme}>
         <MaterialTable
          columns={appointmentColumn}
          data={appointmentHistory}
          title="Appointment History"
          options={{
            filtering: true,sorting: true
          }}
        /></ThemeProvider> )}
    <div className="form-group">
    <label for="exampleFormControlTextarea1">Mission Statement:<span class="required"></span> </label>
    <textarea className="form-control"  name="Mission_Statement" rows="3" placeholder={inputs.church_Mission_Statement
||""}  onChange={handleChange}></textarea>
  </div><br/>
  <div className="form-group">
  {( img ) && ( <img src={bufferToBase64(img.data)}  width="170"/> )}
 
  {/* <ImageUploading
        multiple
        value={images}
         onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg","png"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button  className="btn btn-success"
              style={isDragging ? { color: "red" } : null}
              onClick={onImageUpload}
              {...dragProps}
            >
              Upload/Change Snapshot
            </button>
            &nbsp;
            <button  className="btn btn-success" onClick={onImageRemoveAll}>Remove all images</button>
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image.data_url} alt="" width="200" />
                <div className="image-item__btn-wrapper">
                  <button onClick={() => onImageUpdate(index)}>Update</button>
                  <button onClick={() => onImageRemove(index)}>Remove</button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading> */}
      <br/>
      <div className="row" style={{margin:"1px"}}> 
      <label>AWA(Average Worship Attendance):<span class="required"></span> </label> 
    <div className="col">
      <YearPicker  onChange={inputDate} /> 
      </div> 
      <div className="col">
      <input type="number" className="form-control" min="1"   max="100"name="Percentage"  onChange={inputAttendance}/>
      </div> 
      <div className="col">
      <button onClick={() => {
        var arr=[...awa];

        arr.push(awaObject);
                setAwa(arr);
                var obj= {...inputsEdit};
                obj.awa=arr;
                setInputsEdit(obj)  
       
              }} className="btn btn-success">Add Yearly Attendance</button>      </div> 
      </div> <br/>

                    <ThemeProvider theme={defaultMaterialTheme}>
         <MaterialTable
          columns={columns}
          data={awa}
          title="Yearly Attendance"
          options={{
            filtering: true,sorting: true
          }}
        /></ThemeProvider>
    </div> 

  
  </div> 
  <div className="col">
    <label>Setting :<span class="required"></span> </label>
    <select className="form-select" aria-label="Default select example" name="Setting" value={inputs.church_Setting||""}  onChange={handleChange}>
  <option value="Rural">Rural</option>
  <option value="Urban">Urban</option>
  <option value="Suburban">Suburban</option>
  <option value="Town">Town</option>
  <option value="Church Plant">Church Plant</option>
  </select><br/>
    <div className="form-element">
    <label>City Population:<span class="required"></span> </label>
    <input type="number" className="form-control" name="City_Population" placeholder={inputs.church_City_Population||""}  onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>County Population:<span class="required"></span> </label>
    <input type="number" className="form-control" name="Country_Population" placeholder={inputs.church_Country_Population||""}  onChange={handleChange}/>
    </div><br/>
    <div className="form-group">
    <label for="exampleFormControlTextarea1">Worship:<span class="required"></span> </label>
    <textarea className="form-control" name="question16" rows="1" placeholder={inputs.question16||""}  onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Discipleship:<span class="required"></span> </label>
    <textarea className="form-control" name="question17" rows="1" placeholder={inputs.question17||""}  onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Fellowship & Congregational Care:<span class="required"></span> </label>
    <textarea className="form-control" name="question18" rows="1" placeholder={inputs.question18||""}  onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Missions & Outreach:<span class="required"></span> </label>
    <textarea className="form-control" name="question19" rows="1" placeholder={inputs.question19||""}  onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Number of Classes, Bands & Small Groups:<span class="required"></span> </label>
    <textarea className="form-control" name="question20" rows="1" placeholder={inputs.question20||""}  onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">How many new groups started in last 12 months:<span class="required"></span> </label>
    <textarea className="form-control" name="question21" rows="1" placeholder={inputs.question21||""}  onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Percentage of Congregation Engaged in Accountable Discipleship:<span class="required"></span> </label>
    <textarea className="form-control" name="question22" rows="1" placeholder={inputs.question22||""}  onChange={handleChange}></textarea>
  </div>
    <div className="form-group">
    <label for="exampleFormControlTextarea1">Description:<span class="required"></span> </label>
    <textarea className="form-control" name="Description" rows="3" placeholder={inputs.church_Description||""}  onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Church's Top 3 Strengths:<span class="required"></span> </label>
    <textarea className="form-control" name="Church_Top_3_Strengths" rows="1" placeholder={inputs.church_Church_Top_3_Strengths||""}  onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Opportunities for Ministry:<span class="required"></span> </label>
    <textarea className="form-control" name="Opportunities_for_Ministry" rows="1" placeholder={inputs.church_Opportunities_for_Ministry||""}  onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">What needs to Change:<span class="required"></span> </label>
    <textarea className="form-control" name="What_needs_to_Change" rows="1" placeholder={inputs.church_What_needs_to_Change||""}  onChange={handleChange}></textarea>
  </div> 
  <br/>
  </div> 
  </div>

  <label>Worship Style :<span class="required"></span> </label>
  <div className="row" style={{margin:"1px"}}>  
    <div className="col">
    <select className="form-select" aria-label="Default select example" name="Worship3" onChange={handleWorshipStyle}>
  <option value="contemporary">contemporary</option>
  <option value="blended">blended</option>
  <option value="traditional">traditional</option>
  <option value="liturgical">liturgical</option>
  </select> </div> 
  <div className="col">
    <input required type="time" placeholder="Worship Time" className="form-control" name="Worship1" onChange={handleWorshipStyle}/><br/>
    </div>  
    <div className="col">
    <input required type="time" placeholder="Worship Time" className="form-control" name="Worship2" onChange={handleWorshipStyle}/><br/>
    </div>  
    </div> 
    <button style={{width:"30%"}}onClick={() => {
        var arr=[...worship];

        arr.push(worshipStyleObject);
                setWorship(arr);
           console.log("worshipStyleObject",arr)    
   
       
              }} className="btn btn-success">Add Worship Style</button>    
  <ThemeProvider theme={defaultMaterialTheme}>
         <MaterialTable
          columns={columnsWorship}
          data={worship}
          title="Worship Styles"
          options={{
            filtering: true,sorting: true
          }}
        /></ThemeProvider>



<br/>
<div style={{textAlign:"center"}} >

       
<button onClick={() => {
 console.log("Update2")

     localStorage.setItem("submissionsEdit", JSON.stringify(inputsEdit)); 
         
          
     churchFormApiCall3();
           
            }} 
            className="btn btn-success" >Update</button>
</div>  

    </>
  );
};
export default ChurchFormPageOne;



