import axios from "axios";
import React, {useRef, useState,useEffect } from "react";
import { useHistory } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';

const FinancialFormPageOneAdd = (props) => {
  let submissions= JSON.parse(localStorage.getItem("submissions"))
  // const navigate = useNavigate();
  const history = useHistory();

  const handleChange = (e) => {
    if (e.target.name.includes("financialField")) {
      let combinedString = e.target.name + "%%" + e.target.value;
      props.setInputs((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    console.log(props.inputs)
  };
 
  

  return (
               <div  style={{margin:"3%"}}   >
            <h3 style={{display:"none"}}>FINANCIAL & COMPENSATION</h3> <br/>       

    <div className="row" style={{margin:"1px"}}>  
    <div className="col">
    <h4>Financial Overview</h4> <br/>  
  
    <div className="form-element">
    <label>Annual Giving 	:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField17" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Budget for Programming:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField18" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Budget for Administration:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField19" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Total Budget for Staffing:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField20" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Annual Budget Total:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField21" onChange={handleChange}/>
    </div><br/>
    
    <div className="form-element">
    <label>Budget for Trustees/Facilities:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField22" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>ARP:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField25" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Cont. Ed.:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField26" onChange={handleChange}/>
    </div><br/>
  </div>
  <div className="col">
    <h4>Pastoral Compensation</h4> <br/>  
    <div className="form-element">
    <label>Salary:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField23" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Travel IRS Rate:<span class="required"></span> </label>
    <input required type="number" className="form-control" name="financialField24" onChange={handleChange}/>
    </div><br/>

  


    <div >
    <label>State Income Tax:<span class="required"></span> </label>
    <div class="form-check">
     <input required onChange={handleChange} class="form-check-input" type="radio" name="financialField28" id="exampleRadios1" value="Yes" />
  <label class="form-check-label" for="exampleRadios1">
    Yes
  <span class=""></span> </label>
</div>
<div class="form-check">
  <input required onChange={handleChange} class="form-check-input" type="radio" name="financialField28" id="exampleRadios2" value="No"/>
  <label class="form-check-label" for="exampleRadios2">
    No
  <span class=""></span> </label>
</div>
</div>



<br/>
<div>
    <label>Parsonage:<span class="required"></span> </label>
    <div class="form-check">
   
  <input required  onChange={handleChange} class="form-check-input" type="radio" name="financialField29" id="exampleRadios1" value="Yes"/>
  <label class="form-check-label" for="exampleRadios3">
    Yes
  <span class=""></span> </label>
</div>

<div class="form-check">
  <input  required onChange={handleChange} class="form-check-input" type="radio" name="financialField29" id="exampleRadios2" value="No"/>
  <label class="form-check-label" for="exampleRadios4">
    No
  <span class=""></span> </label>
</div>
{( props.inputs.financialField29 && props.inputs.financialField29=="financialField29%%No") && (    
  <>
<div className="form-element">
    <label>HOUSING ALLOWANCE (if no parsonage available):<span class=""></span> </label>
    <input  type="number" className="form-control" name="financialField27" onChange={handleChange}/>
    </div><br/>
    </> 
)}  
{( props.inputs.financialField29 && props.inputs.financialField29=="financialField29%%Yes") && (    
  <>
   <div className="row" style={{margin:"1px"}}>  
<div className="col">
<div className="form-element">
    <label>Bedrooms:<span class=""></span> </label>
    <input  type="number" className="form-control" name="financialField30" onChange={handleChange}/>
    </div><br/>
</div>
<div className="col">
<div className="form-element">
    <label>Bathrooms:<span class=""></span> </label>
    <input  type="number" className="form-control" name="financialField31" onChange={handleChange}/>
    </div><br/>
</div>
  </div>
  <div className="row" style={{margin:"1px"}}>  
<div className="col">
<div className="form-element">
    <label>Living Room:<span class=""></span> </label>
    <input  type="number" className="form-control" name="financialField32" onChange={handleChange}/>
    </div><br/>
</div>
<div className="col">
<div className="form-element">
    <label>Kitchen:<span class=""></span> </label>
    <input  type="number" className="form-control" name="financialField33" onChange={handleChange}/>
    </div><br/>
</div>
  </div>
  <div className="row" style={{margin:"1px"}}>  
<div className="col">
<div className="form-element">
    <label>Dining Room:<span class=""></span> </label>
    <input  type="text" className="form-control" name="financialField34" onChange={handleChange}/>
    </div><br/>
</div>
<div className="col">
<div className="form-element">
    <label>Family Room:<span class=""></span> </label>
    <input  type="text" className="form-control" name="financialField35" onChange={handleChange}/>
    </div><br/>
</div>
  </div>
  <div className="row" style={{margin:"1px"}}>  
<div className="col">
<div className="form-element">
    <label>Garage:<span class=""></span> </label>
    <input  type="text" className="form-control" name="financialField36" onChange={handleChange}/>
    </div><br/>
</div>
<div className="col">
<div className="form-element">
    <label>Study Office:<span class=""></span> </label>
    </div><br/>
</div>
  </div>
  <div className="row" style={{margin:"1px"}}>  
<div className="col">
<div className="form-element">
    <label>Dates of Major Renovations:<span class=""></span> </label>
    <input  type="date" className="form-control" name="financialField38" onChange={handleChange}/>
    </div><br/>
</div>
<div className="col">
<div className="form-element">
    <label>Date of Construction:<span class=""></span> </label>
    <input  type="date" className="form-control" name="financialField39" onChange={handleChange}/>
    </div><br/>
</div>
  </div>
  <div className="row" style={{margin:"1px"}}>  

<div className="col">
<div className="form-element">
    <label>Comments:<span class=""></span> </label>
    <input  type="text" className="form-control" name="financialField40" onChange={handleChange}/>
    </div><br/>
</div>
  </div>
</> 
)} 
</div> 
 
  
  </div>
  
  </div>

<div style={{textAlign:"center"}} >
  <br/>

          </div>


    </div>
  );
};
export default FinancialFormPageOneAdd;

