import React, {useRef, useState,useEffect,useMemo } from "react";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import YearPicker from "react-year-picker";
import ImageUploading from "react-images-uploading";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const PastorFormPageFiveAdd = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [awa, setAwa] = useState([]); 
  let awaObject={};
 
  const columns = useMemo(
    () => [
   
      {
        field: 'year',
        title: 'Year',
 
        //size: 40,
     
      },
      {
        field: 'percentage',
        title: 'Attendance Percentage',
     
        //size: 20,
    
      },   ],
      [],
    );

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  useEffect(() => {
    let obj={...props.inputs};
    obj.awa=awa;
    console.log(typeof obj,obj)
    props.setInputs(obj);

   }, [awa]);

  const [err, setError] = useState(null);

  const handleChange = (e) => {
    if (e.target.name.includes("question")) {
      let combinedString = e.target.name + "%%" + e.target.value;
      props.setInputs((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else if(e.target.name.includes("input")){
      let combinedString = e.target.name + "%%" + e.target.value;
      props.setInputs((prev) => ({ ...prev, [e.target.name]: combinedString}));
    }
    else{
   
    console.log(e.target.name,e.target.value)
    props.setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
    console.log("inputs2",props.inputs)
  };
  const inputDate = (e) => {
    awaObject.year=e;
  };
  const inputAttendance = (e) => {
    awaObject.percentage=e.target.value;
      };
    
  const handleSubmit = async (e) => {
    console.log("handleSubmit55")
    e.preventDefault();
    window.location.replace('/churchpagetwo');
  };
  const defaultMaterialTheme = createTheme();
  return (
               <div id = "frm1"    >
                <br/>  <br/>  <br/>


   

        <div className="row" style={{margin:"1px"}}>  
    <div className="col">
        <table className="table" >

  <tbody>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Apostle</td>
<td  style={{
    width: "35%"
}}> 
<input name="Clergy66" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
<td  style={{
    width: "35%"
}}> 
<input name="Clergy67" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Prophet</td>
<td  style={{
    width: "15%"
}}> 
<input name="Clergy68" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
<td  style={{
    width: "15%"
}}> 
<input name="Clergy69" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
    </tr>
   
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Evangelist</td>
<td  style={{
    width: "15%"
}}> 
<input name="Clergy70" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
<td  style={{
    width: "15%"
}}> 
<input name="Clergy71" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Shepherd</td>
<td  style={{
    width: "15%"
}}> 
<input name="Clergy72" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
<td  style={{
    width: "15%"
}}> 
<input name="Clergy73" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
    </tr>
    <tr>
      <th style={{display:"none"}} scope="row"></th>
      
      <td style={{
fontStyle: "italic",fontSize:"small"}}>Teacher</td>
<td  style={{
    width: "15%"
}}> 
<input name="Clergy74" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
<td  style={{
    width: "15%"
}}> 
<input name="Clergy75" required type="number" min="1" max="17"className="form-control"style={{width:"100%"}}    onChange={handleChange}/></td>
    </tr>
 
 
   
  </tbody>
</table>
<hr></hr>
<div className="form-group">
    <label for="exampleFormControlTextarea1">What other personality or leadership style indices have you used? (i.e., Meyers-Briggs, Enneagram, DISC, etc.) How did that index describe you? From all you have learned, how would you describe your leadership style? What have you learned from the feedback of others?


<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="Clergy76"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">How do you respond to stress/disagreement/conflict? In conflicted times, how do you understand your pastoral role?


<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="Clergy77"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Of all the areas of pastoral responsibility, what are your strengths? What are not your strengths? How to you intend to maintain your strengths and grow in areas that are not a strength?


<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="Clergy78"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">How do you understand your partnership with the laity of the church? How do you understand the equipping and empowerment of lay leadership?


<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="Clergy79"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">How are you equipping congregations to live a Wesleyan ethos?


<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="Clergy80"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">How are you leading congregations to be externally focused?


<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="Clergy81"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">How do you lead a congregation to celebrate and nurture diversity? (age, racial, ethnic, status, cultural, gender)


<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="Clergy82"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Describe how you are leading congregations in the following areas
<span class="required"></span> </label><br></br>
<label>Visioning:<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="Clergy83"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Evangelism/Faith-sharing: 
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy84"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Strategic Thinking/Planning: 
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy85"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Intentional Discipleship System: 
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy86"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">What do you see as the major challenges of the Church for the next five years?
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy87"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">	In what ministry settings are you most comfortable? (i.e., urban, suburban, rural, staff church, pastor-centered church, new starts, missional churches, chaplaincy, extension or agency settings... or others?)
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy88"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Describe any personal or family issues that might affect your current or any future appointments:
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy89"  rows="1"   onChange={handleChange}></textarea>
  </div>
</div>
<div className="col">
<div className="form-group">
    <label for="exampleFormControlTextarea1">For your current appointment, in this past year:
<span class="required"></span> </label><br></br>
<label>Church membership:  <span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="Clergy90"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Average In-Person Worship Attendance
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy91"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Average On-Line Worship Attendance:
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy92"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">* Please describe your congregation's attendance 'patterns', if any.
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy93"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Percentage of General Church and Annual Conference Connectional Funding Paid:  
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy94"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Baptisms:
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy95"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Professions of Faith:
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy96"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Number of people in small groups/discipleship groups
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy97"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">1st time guests to worship
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy98"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Returning guests to worship: 
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy99"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Number of people in hands-on mission and ministry
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy100"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Number of people served through mission: 
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy101"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Describe your ministry setting (mission field):
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy102"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
  <label htmlFor="exampleFormControlTextarea1">Do you have experience working with staff?<span class="required"></span> </label>
  <br />
  <div className="form-check">
    <input required type="radio" id="radio1" name="Clergy103" value="yes"  onChange={handleChange} className="form-check-input" />
    <label htmlFor="radio1" className="form-check-label">Yes<span class="required"></span> </label>
  </div>
  <div className="form-check">
    <input required  type="radio" id="radio2" name="Clergy103" value="no"  onChange={handleChange} className="form-check-input" />
    <label htmlFor="radio2" className="form-check-label">No<span class="required"></span> </label>
  </div>
</div>
<div className="form-group">
    <label for="exampleFormControlTextarea1">If yes, describe your experience:
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy105"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Describe the geographical area in which you would be open to an appointment?
<span class="required"></span> </label><br></br>
<label>
Anywhere
<span class="required"></span> </label>
    <textarea required className="form-control" id="q"  name="Clergy106"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Only in these States 
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy107"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1">Only in State of Current Appointment 
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy108"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1"> Only Mile Radius from Current Appointment:
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy109"  rows="1"   onChange={handleChange}></textarea>
  </div>
  <div className="form-group">
    <label for="exampleFormControlTextarea1"> Any information that needs to be known that has not been asked about?
<span class="required"></span> </label><br></br>

    <textarea required className="form-control" id="q"  name="Clergy110"  rows="1"   onChange={handleChange}></textarea>
  </div>
</div>

</div>


      

    
    </div>
  );
};
export default PastorFormPageFiveAdd;



