
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.10.0";
import Login from "pages/Login";
import Register from "pages/Register";
;
import ChurchFormPageTwoParent from "pages/ChurchFormPageTwoParent";
import FinancialFormPageOne from "pages/FinancialFormPageOne";
import FinancialFormPageOneParent from "pages/FinancialFormPageOneParent";
import ChurchFormPageTwoParentAdd from "pages/ChurchFormPageTwoParentAdd";
import FinancialFormPageOneParentAdd from "pages/FinancialFormPageOneParentAdd";
import ChurchFormPageOneParent from "pages/ChurchFormPageOneParent"
import ClergyFormPageParent from "pages/ClergyFormPageParent";
import ListSubmissions from "pages/ListSubmissions";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/rtl" component={RTL} />

      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />

      <Route path="/churchpageone" component={ChurchFormPageOneParent} />
      <Route path="/clergyeditpage" component={ClergyFormPageParent} />
      <Route path="/churchpagetwo" component={ChurchFormPageTwoParent} />
      <Route path="/financialpageone" component={FinancialFormPageOneParent} />
      <Route path="/churchpagetwoadd" component={ChurchFormPageTwoParentAdd} />
      <Route path="/financialpageoneadd" component={FinancialFormPageOneParentAdd} />
      <Redirect from="/" to="/admin/listsubmissions" />
    </Switch>
  </BrowserRouter>,
  
  document.getElementById("root")
);
